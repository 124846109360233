import { useEffect } from 'react';

import { CircularProgress } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { Outlet } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '@app/hooks';
import { ModalTypes, getRootActiveModal, getRootUser, rootActions } from '@app/root/slice';
import { AppRoutes } from '@constants/appRoutes';
import { getSessionToken } from '@utility/session';
import { queryKeys } from 'api';

const AppPrivateRoute = () => {
  const queryClient = useQueryClient();

  const user = useAppSelector(getRootUser);
  const activeModal = useAppSelector(getRootActiveModal);

  const dispatch = useAppDispatch();

  const useInfoQueryState = queryClient.getQueryState([
    queryKeys.getUserProfile,
    getSessionToken(),
  ]);

  useEffect(() => {
    if (!user?.id && !activeModal.isAuth && !useInfoQueryState?.data) {
      dispatch(rootActions.setActiveModal(ModalTypes.AUTH));
      dispatch(rootActions.setModalConfig({ redirectOnCloseTo: AppRoutes.home }));
    }
  }, [activeModal, dispatch, useInfoQueryState?.data, user?.id]);

  if (user?.id) {
    return <Outlet />;
  } else if (!user?.id && useInfoQueryState?.fetchStatus === 'fetching') {
    return <CircularProgress size={25} />;
  } else {
    return null;
  }
};

export default AppPrivateRoute;

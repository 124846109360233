import { useMemo } from 'react';

import { IconButton, Paper, Stack, Typography, colors, styled } from '@mui/material';
import {
  BsBarChartLine,
  BsBarChartLineFill,
  BsHouseDoor,
  BsHouseDoorFill,
  BsPersonCircle,
  BsPersonFill,
} from 'react-icons/bs';
import { NavLink } from 'react-router-dom';

import { useAppSelector } from '@app/hooks';
import { getRootUser } from '@app/root/slice';
import { AppRoutes } from '@constants/appRoutes';

import { StackRow } from './StackRow';

export function MobileBottomNavigation() {
  const user = useAppSelector(getRootUser);

  const tabs = useMemo(() => {
    return [
      {
        title: 'Home',
        value: AppRoutes.home,
        icon: <BsHouseDoor />,
        activeIcon: <BsHouseDoorFill />,
      },
      {
        title: 'Dashboard',
        value: AppRoutes.dashboard,
        icon: <BsBarChartLine />,
        activeIcon: <BsBarChartLineFill />,
      },
      ...(user
        ? [
            {
              title: 'Profile',
              value: AppRoutes.profile({ id: `${user.userName}` }),
              icon: <BsPersonCircle />,
              activeIcon: <BsPersonFill />,
            },
          ]
        : []),
    ];
  }, [user]);

  return (
    <>
      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 11111 }} elevation={3}>
        <StackRow
          alignItems='center'
          justifyContent='space-between'
          sx={{ px: 2, py: 1, maxHeight: '3.4em' }}
        >
          {tabs.map((item) => (
            <NavLink key={item.title} to={item.value}>
              {({ isActive }) => (
                <StyledIconTitleStack
                  alignItems='center'
                  justifyContent='center'
                  $isActive={isActive}
                >
                  <IconButton
                    sx={{ p: 0, m: 0, ...(!isActive && { color: colors.blueGrey[500] }) }}
                  >
                    {isActive && item.activeIcon ? item.activeIcon : item.icon}
                  </IconButton>
                  <Typography
                    variant='caption'
                    sx={{ fontSize: '0.7rem', color: isActive ? colors.blue[700] : 'GrayText' }}
                  >
                    {item.title}
                  </Typography>
                </StyledIconTitleStack>
              )}
            </NavLink>
          ))}
        </StackRow>
      </Paper>
    </>
  );
}

const StyledIconTitleStack = styled(Stack)<{ $isActive: boolean }>(({ $isActive }) => ({
  svg: {
    fontSize: '1.1rem',
    ...($isActive && {
      color: colors.blue[700],
    }),
  },
}));

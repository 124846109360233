import imageCompression, { type Options } from 'browser-image-compression';

export async function compressImage(file: File, maxSizeMB = 0.05, maxWidthOrHeight = 400) {
  const imageFile = file;
  const options: Options = {
    maxSizeMB,
    maxWidthOrHeight,
    useWebWorker: true,
  };
  try {
    const compressedFile = await imageCompression(imageFile, options);
    return compressedFile;
  } catch (error) {
    console.log(error);
    return null;
  }
}

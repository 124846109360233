import { RefObject, useEffect } from 'react';

type IntersectionCallback = () => void;

const useIntersection = (
  elementRef: RefObject<HTMLElement>,
  offset: string | number,
  callback: IntersectionCallback,
  disabled?: boolean
) => {
  useEffect(() => {
    const element = elementRef.current;
    if (!element || disabled) {
      return;
    }

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            callback();
          }
        });
      },
      {
        root: null,
        rootMargin: `${offset}px`,
        threshold: 0.5,
      }
    );

    observer.observe(element);

    return () => {
      observer.unobserve(element);
      observer.disconnect();
    };
  }, [elementRef, offset, callback, disabled]);
};

export default useIntersection;

import React from 'react';

import { TextareaAutosize, TextareaAutosizeProps, Typography, colors, styled } from '@mui/material';

import { StackRow } from './StackRow';
import { InputErrorAlert } from './TextInput';

export const TextAreaAutoSizeStyled = styled(TextareaAutosize)(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.fontSize,
  padding: '1rem',
  resize: 'none',
  width: '100%',
  boxSizing: 'border-box',
  borderRadius: '0.8rem',
  borderColor: colors.grey[400],
  '&::placeholder': {
    color: colors.grey[500],
  },
}));

export type TextLengthInfoProps = { maxLength: number; count: number };

type Props = TextareaAutosizeProps & {
  textInfo?: TextLengthInfoProps;
  disableTextInfo?: boolean;
  errorMsg?: string;
};

export function TextLengthInfo({ count, maxLength }: TextLengthInfoProps) {
  return (
    <Typography textAlign='right' variant='body2' sx={{ fontSize: '0.85rem' }}>
      <Typography component='span' sx={{ fontWeight: 'bold', fontSize: '0.85rem' }}>
        {count}
      </Typography>
      /{maxLength}
    </Typography>
  );
}

export function TextArea({ textInfo, disableTextInfo, errorMsg, ...rest }: Props) {
  return (
    <>
      <TextAreaAutoSizeStyled {...rest} />
      <StackRow justifyContent={errorMsg ? 'space-between' : 'flex-end'} alignItems='center'>
        {errorMsg && <InputErrorAlert msg={errorMsg} />}
        {!disableTextInfo && textInfo && <TextLengthInfo {...textInfo} />}
      </StackRow>
    </>
  );
}

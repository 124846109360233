// EmptyState.tsx
import React, { ReactNode } from 'react';

import { Box, Button, Typography, colors } from '@mui/material';
import { BsArrowRepeat, BsEmojiGrimace } from 'react-icons/bs'; // React Icons

interface EmptyStateProps {
  show: boolean;
  children: ReactNode;
  title?: string;
  description?: string;
  height?: string;
}

export const ErrorState: React.FC<EmptyStateProps> = ({
  children,
  show,
  title = `Oop's something went wrong!`,
  description = `We are looking into it. Try refreshing the page.`,
  height,
}) => {
  if (!show) return children;

  return (
    <Box
      display='flex'
      flexDirection='column'
      alignItems='center'
      justifyContent='center'
      height={height ?? '100%'}
    >
      <BsEmojiGrimace size={50} />
      <Typography variant='h5' mt={2}>
        {title}
      </Typography>
      <Typography variant='body2' color='textSecondary' mt={1} textAlign='center'>
        {description}
      </Typography>
      <Button
        onClick={() => window.location.reload()}
        sx={{ mt: 3, bgcolor: colors.green[500] }}
        startIcon={<BsArrowRepeat />}
      >
        Refresh
      </Button>
    </Box>
  );
};

import { QueryClient, QueryKey } from '@tanstack/react-query';

import { LikeDislikeType } from '@constants/index';

import { getValueFromLikeDislikeType } from './likesDislikes';

// LD = likes dislikes
export const persistLDChangeInQueryData =
  (queryKey: QueryKey, queryClient: QueryClient, setDataQueryEnabled: (x: boolean) => void) =>
  (postId: number) =>
  async (updatedLikes: number, updatedDislikes: number, updatedType: LikeDislikeType) => {
    setDataQueryEnabled(false);

    try {
      // Wait for ongoing data fetching to complete
      if (queryClient.getQueryState(queryKey)?.fetchStatus === 'fetching')
        await queryClient.cancelQueries(queryKey);
    } catch (err) {
      // Handle cancellation error if needed
      console.error('Error canceling query:', err);
    }

    const data: any = queryClient.getQueryData(queryKey);

    if (!data?.pages) {
      setDataQueryEnabled(true);
      return;
    }

    // Modify the like of that element
    const updatedData = data?.pages?.map((innerArray: any) => {
      const updatedInnerArray = innerArray.map((item: any) => {
        if (item.id === postId) {
          // Found an item with the matching postId

          return {
            ...item,
            likeCount: updatedLikes,
            dislikeCount: updatedDislikes,
            activeUserVote: getValueFromLikeDislikeType(updatedType),
          };
        } else {
          return item;
        }
      });
      return updatedInnerArray;
    });

    queryClient.setQueryData(queryKey, {
      ...data,
      pages: updatedData,
    });

    setDataQueryEnabled(true);
  };

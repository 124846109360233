type EventHandlerWithPreventDefault = (event: React.MouseEvent<HTMLButtonElement>) => void;

export function preventDefaultWrapper(
  handler?: EventHandlerWithPreventDefault,
  disablePropagation?: boolean
): EventHandlerWithPreventDefault {
  return (event) => {
    if (disablePropagation) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (handler) {
      handler(event);
    }
  };
}

// EmptyState.tsx
import React, { ReactNode } from 'react';

import { Box, Typography } from '@mui/material';
import { BsBox } from 'react-icons/bs'; // Bootstrap Icons
import { RiErrorWarningFill } from 'react-icons/ri'; // React Icons

interface EmptyStateProps {
  icon: 'bs' | 'ri'; // 'bs' for Bootstrap Icons, 'ri' for React Icons
  title: string;
  description: string;
  show: boolean;
  children: ReactNode;
  height?: string;
}

export const EmptyState: React.FC<EmptyStateProps> = ({
  children,
  show,
  icon,
  title,
  description,
  height,
}) => {
  const selectedIcon = icon === 'bs' ? <BsBox size={50} /> : <RiErrorWarningFill size={50} />;

  if (!show) return children;

  return (
    <Box
      display='flex'
      flexDirection='column'
      alignItems='center'
      justifyContent='center'
      height={height ?? '100%'}
    >
      {selectedIcon}
      <Typography variant='h5' mt={2} sx={{ fontSize: { xs: '1rem', lg: '1.6rem' } }}>
        {title}
      </Typography>
      <Typography variant='body2' color='textSecondary' mt={1} textAlign='center'>
        {description}
      </Typography>
    </Box>
  );
};

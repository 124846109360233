import React, {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useMutation } from '@tanstack/react-query';
import DOMPurify from 'dompurify';
import { debounce, get, isEmpty, isEqual, isNil, pick, pickBy } from 'lodash';

import { compressImage } from '@components/PicCropper/utils/compressor';
import { AppRoutes } from '@constants/appRoutes';
import { useRouter } from '@hooks/useRouter';
import { useToast } from '@hooks/useToast';
import ReactGA from '@libs/GoogleAnalytics';
import useExistingDraft from '@pages/NewPost/useExistingDraft';
import uploadImage from '@utility/imageUploader';
import { createNewPost, mutationKey, updatePostById } from 'api';
import { GetUserOwnPostResponse, PostPayloadComplete } from 'api/post/types';

type ActiveStep = 1 | 2 | 3;

type FormActivityStates = {
  isUpdating: boolean;
};

type AllFormInputs = Step1Inputs | Step2Inputs | Step3Inputs;

type FileUploadFn = (file: File) => Promise<void>;

type StepChangeFn = (type: 'next' | 'back') => void;

type HandleTempFormDataUpdateFn = (data: unknown) => void;

type IExistingDraftContext = {
  draft: ReturnType<typeof useExistingDraft>;
  formData: null | AllFormInputs;
  handleFormUpdate: HandleFormUpdateFn;
  activeStep: ActiveStep | null;
  updateFormDataState: (
    key: keyof (Step1Inputs & Step2Inputs & Step3Inputs) | 'makers',
    value: unknown
  ) => void;
  handleGalleryUpload: FileUploadFn;
  handleImageUpload: FileUploadFn;
  handleStepChange: StepChangeFn;
  formActivityStates: FormActivityStates;
  handleTempFormDataUpdate: HandleTempFormDataUpdateFn;
  tempState: undefined | unknown;
};

export type Step1Inputs = Pick<
  PostPayloadComplete,
  'name' | 'tagline' | 'description' | 'tags' | 'progLangs'
>;
export type Step2Inputs = {
  youtubeUrl?: string | null;
};
export type Step3Inputs = Pick<
  PostPayloadComplete,
  'pricingModel' | 'isOpenSource' | 'postedByMaker' | 'usefulLinks'
>;

type HandleFormUpdateFn = (step: ActiveStep, changeStep?: boolean) => (data: AllFormInputs) => void;

type TempFormDataState = { [x: string]: unknown };

const ExistingDraftContext = createContext<IExistingDraftContext | null>(null);

export const ExistingDraftProvider = ({ children }: PropsWithChildren) => {
  const { promiseToast, infoToast } = useToast();
  const draft = useExistingDraft();
  const { replace, navigateTo } = useRouter();

  const [activeStep, setActiveStep] = useState<ActiveStep | null>(null);
  const [formData, setFormData] = useState<IExistingDraftContext['formData']>(null);
  const [formActivityStates, setFormActivityStates] = useState<FormActivityStates>({
    isUpdating: false,
  });
  const [formDataTempState, setFormDataTempState] = useState<TempFormDataState>({ '1': undefined });

  const postCreateMutate = useMutation([mutationKey.createNewPost], createNewPost);

  const updateFormDataState: IExistingDraftContext['updateFormDataState'] = useCallback(
    (key, value) => {
      setFormData((prev) => ({ ...prev, [key]: value }));
    },
    []
  );

  const tempState = useMemo(() => {
    if (!activeStep) return undefined;
    return formDataTempState?.[`${activeStep}`] ?? undefined;
  }, [activeStep, formDataTempState]);

  const deleteElementsWithBr = (htmlString: string) => {
    const parser = new DOMParser();

    // Parse HTML string to DOM
    const parsedDOM = parser.parseFromString(htmlString, 'text/html');

    // Find elements with only nested <br> elements and remove them
    Array.from(parsedDOM.body.children).forEach((element) => {
      if (element.children.length === 1 && element.children[0].tagName === 'BR') {
        element.remove();
      }
    });

    // Convert the modified DOM back to HTML string
    const modifiedHTMLString = parsedDOM.body.innerHTML.toString();

    return DOMPurify.sanitize(modifiedHTMLString);
  };

  const generateUpdatePayloadByStep = useCallback((step: ActiveStep, data: AllFormInputs) => {
    if (step === 1)
      return {
        ...pick(data as Step1Inputs, 'description', 'name', 'progLangs', 'tagline', 'tags'),
        description: deleteElementsWithBr(get(data, 'description') as unknown as string),
      };
    else if (step === 2)
      return pickBy(
        data as Step2Inputs,
        (value, key) => key === 'youtubeUrl' && !isNil(value) && !isEmpty(value)
      );
    else if (step === 3)
      return pick(
        data as Step3Inputs,
        'isOpenSource',
        'postedByMaker',
        'pricingModel',
        'usefulLinks'
      );
  }, []);

  const isDataUpdated = useCallback(
    (step: ActiveStep, data: AllFormInputs) => {
      if (step === 1)
        return !isEqual(
          pick(data as Step1Inputs, 'description', 'name', 'progLangs', 'tagline', 'tags'),
          pick(formData, 'description', 'name', 'progLangs', 'tagline', 'tags')
        );
      else if (step === 2)
        return !isEqual(
          pickBy(
            data as Step2Inputs,
            (value, key) => key === 'youtubeUrl' && !isNil(value) && !isEmpty(value)
          ),
          pickBy(formData, (value, key) => key === 'youtubeUrl' && !isNil(value) && !isEmpty(value))
        );
      else if (step === 3)
        return !isEqual(
          {
            ...pick(
              data as Step3Inputs,
              'isOpenSource',
              'postedByMaker',
              'pricingModel',
              'usefulLinks'
            ),
            ...({
              isOpenSource: (data as any).isOpenSource === 'yes',
              postedByMaker: (data as any).postedByMaker === 'yes',
            } as any),
          },
          pick(formData, 'isOpenSource', 'postedByMaker', 'pricingModel', 'usefulLinks')
        );
    },
    [formData]
  );

  const handlePostCreate = useCallback(
    async (data: Step1Inputs) => {
      try {
        const res = await promiseToast(postCreateMutate.mutateAsync(data), {
          error: 'Oops! Error saving your changes. Please try again. ❌',
          loading: 'Saving your changes... ⌛',
          success: 'Changes saved successfully! ✅',
        });

        // Track successful creation
        ReactGA.event({
          category: 'SubmitPostForm',
          action: 'post_created',
          label: `Created new post #${res.id}`, // Include the post ID as a label for easy identification
          value: 1, // Optional value (e.g., potential revenue from this post)
        });
        setFormData(data);
        replace({ search: `draft_id=${res.id}` });
        setActiveStep(2);
      } catch (error) {
        console.error('Error creating post:', error);
        // Track creation failure
        ReactGA.event({
          category: 'Post',
          action: 'post_creation_failed',
          label: 'New post', // Use post title if available, otherwise fallback
          value: -1,
        });
        ReactGA.send({
          error: error,
          action: 'post_creation_failed',
          label: 'New post', // Use post title if available, otherwise fallback
        });
      }
    },
    [postCreateMutate, promiseToast, replace]
  );

  const handleStepChange: StepChangeFn = useCallback(
    (type) => {
      if (!activeStep) {
        return setActiveStep(1);
      }

      let step: number = activeStep;
      if (type === 'back' && activeStep > 1) {
        step = activeStep - 1;
      } else if (type === 'next' && activeStep < 3) {
        step = activeStep + 1;
      }

      ReactGA.event({
        category: 'SubmitPostForm',
        action: 'step_finised',
        label: `Step ${activeStep}`,
        value: +new Date(),
      });
      setActiveStep(step as ActiveStep);
    },
    [activeStep, setActiveStep]
  );

  const handlePostUpdate = useCallback(
    async (step: ActiveStep, data: AllFormInputs, changeStep: boolean) => {
      if (!draft.draftData) return;

      if (isDataUpdated(step, data)) {
        try {
          setFormActivityStates((prev) => ({ ...prev, isUpdating: true }));
          const payload = generateUpdatePayloadByStep(step, data);
          if (!payload) return;
          const res = await promiseToast(updatePostById(draft.draftData.id, payload), {
            error: `Oops! Something went wrong while publishing changes. 😟`,
            loading: `Saving changes... Please wait. ⌛`,
            success: (data) => {
              if (data.isPublished && activeStep === 3) {
                // Track creation failure
                ReactGA.event({
                  category: 'SubmitPostForm',
                  action: draft.draftData?.isPublished
                    ? 'post_update_published'
                    : 'new_post_published',
                  label: draft.draftData?.isPublished
                    ? `Post updated ${data.id}`
                    : `New post published ${data.id}`, // Use post title if available, otherwise fallback
                  value: 1,
                });
                // return `Changes published successfully! Your library is now public. 🎉`;
              }
              return `Changes saved successfully!`;
            },
          });

          setFormData((prev) => ({ ...prev, ...data, ...res }));
          setFormDataTempState((prev) => ({ ...prev, [`${activeStep}`]: null }));
        } catch (error) {
          console.error('Error creating post:', error);
          // Track creation failure
          ReactGA.event({
            category: 'SubmitPostForm',
            action: 'post_update_error',
            label: `post update error ${draft.draftData.id}`, // Use post title if available, otherwise fallback
            value: -1,
          });
          ReactGA.send({
            error: error,
            action: 'post_update_error',
            label: `post update error ${draft.draftData.id}`,
          });
        } finally {
          setFormActivityStates((prev) => ({ ...prev, isUpdating: false }));
        }
      }

      if (activeStep === 3 && !draft.draftData.isPublished) {
        navigateTo(AppRoutes.payment({ id: `${draft.draftData?.id}` }));
        return;
      }

      if (changeStep) {
        const formState = formData as GetUserOwnPostResponse;
        if (
          activeStep === 2 &&
          (!formState ||
            !Object.values(formState?.galleryImages ?? {})?.length ||
            !formState.thumbnailUrl)
        ) {
          return infoToast(
            'Please uploading Thumbnail & Preview shots (at-least 1) to move ahead',
            { id: 'ThumbShotError' }
          );
        }

        handleStepChange('next');
      }

      if (activeStep === 3) {
        replace(AppRoutes.dashboard);
      }
    },
    [
      activeStep,
      draft.draftData,
      formData,
      generateUpdatePayloadByStep,
      handleStepChange,
      infoToast,
      isDataUpdated,
      promiseToast,
      replace,
    ]
  );

  const handleImageUpload = useCallback(
    async (file: File) => {
      if (!draft.draftData) return;
      const res = await promiseToast(
        uploadImage<Pick<Library, 'thumbnailUrl'>>({
          path: `/posts/${draft.draftData.id}/thumbnail`,
          file: file,
          method: 'post',
        }),
        {
          error: `Oops! Something went wrong while uploading the thumbnail. 😟`,
          loading: `Uploading thumbnail... Please wait. ⌛`,
          success: `Thumbnail uploaded successfully!`,
        },
        { id: 'ThumbnailUpload' }
      );
      draft.updateQueryResDirectly('thumbnailUrl', res.data.thumbnailUrl);
    },
    [draft, promiseToast]
  );

  const handleGalleryUpload = useCallback(
    async (file: File) => {
      try {
        if (!draft.draftData) return;

        const compressedImage = await compressImage(file, 0.5, 1920);
        if (!compressedImage) throw new Error('Error uploading image');

        const res = await promiseToast(
          uploadImage<Pick<Library, 'galleryImages'>>({
            path: `/posts/${draft.draftData.id}/gallery`,
            file: compressedImage,
            method: 'post',
          }),
          {
            error: (error) => {
              console.log(error);
              return (
                error?.message || `Oops! Something went wrong while uploading preview shot. 😟`
              );
            },
            loading: `Uploading preview shot... Please wait. ⌛`,
            success: () => `Preview shot uploaded successfully!`,
          },
          { id: 'PreviewShotUpload' }
        );
        draft.updateQueryResDirectly('galleryImages', res.data.galleryImages);
      } catch (error: any) {
        console.error(error);
      }
    },
    [draft, promiseToast]
  );

  const handleFormUpdate: HandleFormUpdateFn = useCallback(
    (step, changeStep) => async (data) => {
      if (step === 1 && !draft.isEnabled) {
        await handlePostCreate(data as Step1Inputs);
        return;
      }
      return await handlePostUpdate(step, data, changeStep ?? false);
    },
    [draft.isEnabled, handlePostCreate, handlePostUpdate]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleTempFormDataUpdate = useCallback(
    debounce((data: any) => {
      if (!activeStep) return;
      setFormDataTempState((prev) => ({ ...prev, [`${activeStep}`]: data }));
    }, 300), // Set the debounce delay (e.g., 300 milliseconds)
    [activeStep]
  );

  useEffect(() => {
    if (!activeStep) setActiveStep(1);
  }, [activeStep]);

  useEffect(() => {
    if (draft.draftData) setFormData((prev) => ({ ...prev, ...draft.draftData }));
  }, [draft.draftData]);

  useEffect(() => {
    if (activeStep)
      ReactGA.event({
        category: 'SubmitPostForm',
        action: 'step_viewed',
        label: `Step ${activeStep}`,
        value: +new Date(),
      });
  }, [activeStep]);

  const values = useMemo(() => {
    return {
      draft,
      formData,
      tempState,
      activeStep,
      formActivityStates,
      handleFormUpdate,
      handleStepChange,
      handleImageUpload,
      updateFormDataState,
      handleGalleryUpload,
      handleTempFormDataUpdate,
    };
  }, [
    draft,
    formData,
    activeStep,
    formActivityStates,
    tempState,
    handleFormUpdate,
    updateFormDataState,
    handleGalleryUpload,
    handleImageUpload,
    handleStepChange,
    handleTempFormDataUpdate,
  ]);

  return <ExistingDraftContext.Provider value={values}>{children}</ExistingDraftContext.Provider>;
};

export const useExistingDraftContext = () => {
  const context = useContext(ExistingDraftContext);
  if (!context) {
    throw new Error('useExistingDraftContext must be used within an ExistingDraftProvider');
  }
  return context;
};

export type ProfileRouteRouteParams = { id: string };
export type PostsByTagRouteParams = { id: string; tagName: string };
export type DetailsRouteParams = { id: string };
export type PublishPropRouteParams = { id: string; type: 'fail' | 'success' };
export type EditPostRouteParams = { id: string };

export const AppRoutes = {
  home: '/',

  // Posts
  newPost: '/post/new',
  postsByTag: ({ id, tagName }: PostsByTagRouteParams) => `/tag/${id}/${tagName}`,
  managePost: ({ id }: EditPostRouteParams) => `/post/${id}/manage`,

  // Dashboard
  dashboard: '/dashboard',

  // Profile
  profile: ({ id }: ProfileRouteRouteParams) => `/profile/${id}`,

  // Details
  postDetail: ({ id }: DetailsRouteParams) => `/lib/${id}`,

  // Collection
  collection: '/collection',

  // Community
  community: '/community',

  // Payment
  payment: ({ id }: DetailsRouteParams) => `/publish/pro/${id}`,
  proPublish: ({ id, type }: PublishPropRouteParams) => `/publish/pro/${type}/${id}`,
};

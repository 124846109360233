import React from 'react';

import { IconButton, Tooltip, styled } from '@mui/material';
import { BsLinkedin, BsTwitter } from 'react-icons/bs';

const Footer = () => {
  return (
    <Root>
      <Tooltip title='LinkedIn'>
        <IconButton
          aria-label='LinkedIn'
          component='a'
          target='_blank'
          rel='noopener noreferrer'
          href='https://in.linkedin.com/company/frulow?trk=public_post_feed-actor-name'
        >
          <BsLinkedin className='icon' />
        </IconButton>
      </Tooltip>
      <Tooltip title='Twitter'>
        <IconButton
          target='_blank'
          rel='noopener noreferrer'
          aria-label='Twitter'
          component='a'
          href='https://twitter.com/FrulowOfficial'
        >
          <BsTwitter className='icon' />
        </IconButton>
      </Tooltip>
    </Root>
  );
};

const Root = styled('div')(({ theme }) => ({
  backgroundColor: 'white',
  padding: theme.spacing(1),
  display: 'flex',
  justifyContent: 'center',
  borderRadius: theme.shape.borderRadius,

  '& .MuiIconButton-root': {
    marginRight: '10px',
  },

  '.icon': {
    color: theme.palette.primary.main,
    fontSize: 24,
    transition: 'color 0.3s ease',
    '&:hover': {
      color: '#55acee', // Twitter color
    },
  },
}));

export default Footer;

export function generateRandomArrayWithIndexes(length: number): number[] {
  if (length < 0) {
    throw new Error('Array length must be non-negative');
  }

  const randomArray: number[] = [];

  for (let i = 0; i < length; i++) {
    randomArray.push(i);
  }

  return randomArray;
}

export function getRandomValueFromArray(array: any[]): any {
  // Generate a random index between 0 and the length of the array minus 1
  const randomIndex = Math.floor(Math.random() * array.length);

  // Use the random index to select a random value from the array
  const randomValue = array[randomIndex];

  return randomValue;
}

export function isValidArray<T>(array: T[] | undefined): T[] | null {
  return Array.isArray(array) && array.length > 0 ? array : null;
}

export function isValidTagArray(
  array: Pick<Tag, 'id' | 'name'>[] | undefined
): Pick<Tag, 'id' | 'name'>[] | null {
  return Array.isArray(array) && array.length > 0
    ? array.filter((item) => item.name && item.id)
    : null;
}

export function isValidMakersArray(array: Maker[] | undefined): Maker[] {
  return Array.isArray(array) && array.length > 0
    ? array.filter((item) => item.id && item.label)
    : [];
}

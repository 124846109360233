import { baseURL } from '@constants/index';

import { getSessionToken } from './session';

interface Props {
  path: string;
  file: File;
  method?: 'post' | 'put' | 'patch';
}

// Define a generic type for the response data
type ResponseData<T> = {
  data: T;
  // You can add more properties as needed, e.g., status, message, etc.
};

const uploadImage = async <T>({ file, path, method }: Props): Promise<ResponseData<T>> => {
  const apiUrl = baseURL + path;

  const formData = new FormData();
  formData.append('file', file);

  try {
    const headers = {
      Authorization: `Bearer ${getSessionToken()}`,
    };

    const delay = process.env.NODE_ENV === 'development' ? 2000 : 0;

    const res = await new Promise<ResponseData<T>>((resolve, reject) => {
      setTimeout(() => {
        fetch(apiUrl, {
          method: method?.toUpperCase() ?? 'POST',
          body: formData,
          headers,
        })
          .then(async (response) => {
            if (!response.ok) {
              const errorResponse = await response.json();
              throw new Error(errorResponse?.message ?? 'Network response was not ok');
            }
            return response.json();
          })
          .then((data: T) => {
            console.log('Image uploaded successfully:', data);
            return resolve({ data });
          })
          .catch((error) => {
            reject(error);
          });
      }, delay);
    });

    return res;
  } catch (error) {
    console.error('Error uploading image:', error);
    throw error;
  }
};

export default uploadImage;

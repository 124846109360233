import React from 'react';

import { Skeleton, Stack } from '@mui/material';

export default function ProfileInfoBox() {
  return (
    <Stack alignItems='center' justifyContent='center' spacing={2} sx={{ p: 3, mt: 3 }}>
      <Skeleton width='8rem' height='8rem' sx={{ borderRadius: '50%' }} />
      <Skeleton width='50%' height='3rem' />
      <Stack direction='row' alignItems='center' justifyContent='center' spacing={2}>
        <Skeleton width='6rem' height='1.4rem' />
        <Skeleton width='10rem' height='1.4rem' />
      </Stack>
      <Skeleton width='100%' height='4rem' sx={{ borderRadius: 3 }} />
    </Stack>
  );
}

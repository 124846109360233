import { useMemo } from 'react';

import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import { ErrorBoundary } from '@components/ErrorBoundary';

import allRoutes from './routes';

function AppRouter() {
  const router = useMemo(
    () => createBrowserRouter([{ errorElement: <ErrorBoundary />, children: [...allRoutes] }]),
    []
  );

  return <RouterProvider router={router} />;
}

export default AppRouter;

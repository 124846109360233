import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';

import { IconButton, Stack, styled } from '@mui/material';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';

const ScrollerDiv = styled('div')`
  overflow-x: auto;
  position: relative;
  overscroll-behavior-x: none;

  /* Hide the scrollbar */
  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  /* Hide scrollbar track */
  ::-webkit-scrollbar-track {
    display: none;
  }

  /* Hide scrollbar thumb (the draggable part) */
  ::-webkit-scrollbar-thumb {
    display: none;
  }

  /* Hide scrollbar button (arrows at the ends) */
  ::-webkit-scrollbar-button {
    display: none;
  }
`;

const ArrowButton = styled(IconButton)(({ theme }) => ({}));

type HorizontalScrollerProps = PropsWithChildren<{ disableArrow?: true }>;

const HorizontalScroller: React.FC<HorizontalScrollerProps> = ({ children, disableArrow }) => {
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(true);

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!containerRef.current) return;

    const container = containerRef.current;

    const handleScroll = () => {
      setCanScrollLeft(container.scrollLeft > 0);
      setCanScrollRight(container.scrollLeft + container.clientWidth <= container.scrollWidth - 40);
    };

    container.addEventListener('scroll', handleScroll);

    // Cleanup
    return () => {
      container.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (!containerRef.current) return;
    const container = containerRef.current;
    setCanScrollLeft(container.scrollLeft > 0);
    setCanScrollRight(container.scrollLeft + container.clientWidth <= container.scrollWidth - 40);
  }, []);

  const handleScrollLeft = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const container = containerRef.current;
    if (container) {
      container.scrollLeft -= 100; // Adjust the value as needed
    }
  };

  const handleScrollRight = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();

    const container = containerRef.current;
    if (container) {
      container.scrollBy({ left: container.clientWidth, behavior: 'smooth' });
    }
  };

  return (
    <Stack
      direction='row'
      alignItems='center'
      justifyContent='flex-start'
      sx={{ overflow: 'hidden' }}
      onClick={(e) => e.stopPropagation()}
    >
      {canScrollLeft && !disableArrow && (
        <ArrowButton onClick={handleScrollLeft} size='small'>
          <BsChevronLeft />
        </ArrowButton>
      )}
      <ScrollerDiv
        ref={containerRef}
        sx={{
          boxShadow: [
            canScrollRight && '-10px 0px 20px -20px rgba(0, 0, 0, 0.2) inset',
            canScrollLeft && '10px 0px 20px -20px rgba(0, 0, 0, 0.2) inset',
          ]
            .filter((item) => item)
            .join(', '),
        }}
      >
        {children}
      </ScrollerDiv>

      {canScrollRight && !disableArrow && (
        <ArrowButton onClick={handleScrollRight} size='small'>
          <BsChevronRight />
        </ArrowButton>
      )}
    </Stack>
  );
};

export default HorizontalScroller;

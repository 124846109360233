import React, { lazy, useEffect } from 'react';

import { Box, LinearProgress } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { Outlet } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '@app/hooks';
import { getRootActiveModal, getRootUser, rootActions } from '@app/root/slice';
import { Appbar } from '@components/Appbar';
import LoginModal from '@components/LoginModal/LoginModal';
import { SearchModal } from '@components/SearchModal';
import { ConfirmProvider } from '@hoc/confirm.context';
import useResponsive from '@hooks/useResponsive';
import ReactGA from '@libs/GoogleAnalytics';
import { LazyRoute } from '@router/LazyRoute';
import { getSessionToken } from '@utility/session';
import { getUserOwnProfile, queryKeys } from 'api';

const GoogleLoginProvider = lazy(() =>
  import('@components/LoginModal/GoogleLogin').then((module) => ({
    default: module.GoogleLoginProvider,
  }))
);

const MobileBottomNavigation = lazy(() =>
  import('@components/MobileBottomNavigation').then((module) => ({
    default: module.MobileBottomNavigation,
  }))
);
const AppbarMobile = lazy(() =>
  import('@components/AppbarMobile').then((module) => ({ default: module.AppbarMobile }))
);
const OTPModal = lazy(() =>
  import('@components/OTPModal').then((module) => ({ default: module.OTPModal }))
);
const PhotoUploaderModal = lazy(() =>
  import('@components/PhotoUploaderModal').then((module) => ({
    default: module.PhotoUploaderModal,
  }))
);
const ProfileModal = lazy(() =>
  import('@components/ProfileModal').then((module) => ({
    default: module.ProfileModal,
  }))
);
const ReportModal = lazy(() =>
  import('@components/ReportModal').then((module) => ({
    default: module.ReportModal,
  }))
);

const CollectionModal = lazy(() =>
  import('@components/CollectionModal').then((module) => ({
    default: module.CollectionModal,
  }))
);
const SelectCollectionModal = lazy(() =>
  import('@components/CollectionModal').then((module) => ({
    default: module.SelectCollectionModal,
  }))
);
const UserNameModal = lazy(() =>
  import('@components/UserNameModal').then((module) => ({
    default: module.UserNameModal,
  }))
);

function Layout() {
  const { isDownLg } = useResponsive();
  const dispatch = useAppDispatch();
  const user = useAppSelector(getRootUser);
  const activeModal = useAppSelector(getRootActiveModal);

  const userProfileQuery = useQuery(
    [queryKeys.getUserProfile, getSessionToken()],
    getUserOwnProfile,
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: 2,
      enabled: !!getSessionToken(),
    }
  );

  useEffect(() => {
    if (userProfileQuery.data) {
      ReactGA.set({ userId: userProfileQuery.data.id }); // Set the user ID after initialization
      dispatch(rootActions.setUser(userProfileQuery.data));
    }
  }, [dispatch, userProfileQuery.data]);

  useEffect(() => {
    if (user) {
      dispatch(rootActions.checkUserValidity());
    }
  }, [dispatch, user]);

  return (
    <ConfirmProvider>
      <Box>
        {activeModal.isSearch && (
          <SearchModal
            open={true}
            onClose={() => {
              console.log('Here');
            }}
          />
        )}
        {activeModal.isAuth && <LoginModal />}
        {activeModal.isEmailOtpVerify && (
          <LazyRoute>
            <OTPModal />
          </LazyRoute>
        )}
        {activeModal.isPhotoUpload && (
          <LazyRoute>
            <PhotoUploaderModal />
          </LazyRoute>
        )}
        {activeModal.isProfile && (
          <LazyRoute>
            <ProfileModal />
          </LazyRoute>
        )}

        {activeModal.isReport && (
          <LazyRoute>
            <ReportModal />
          </LazyRoute>
        )}

        {activeModal.isCreateCollection && (
          <LazyRoute>
            <CollectionModal />
          </LazyRoute>
        )}

        {activeModal.isAddToCollection && (
          <LazyRoute>
            <SelectCollectionModal />
          </LazyRoute>
        )}

        {activeModal.isUserNameSelect && (
          <LazyRoute>
            <UserNameModal />
          </LazyRoute>
        )}
        {isDownLg ? (
          <LazyRoute>
            <AppbarMobile />
          </LazyRoute>
        ) : (
          <Appbar />
        )}
        {userProfileQuery.isFetching ? <LinearProgress /> : <Outlet />}
        {isDownLg && (
          <LazyRoute>
            <MobileBottomNavigation />
          </LazyRoute>
        )}
        {!userProfileQuery.isFetching && !user?.id && (
          <LazyRoute>
            <GoogleLoginProvider variant='hidden' />
          </LazyRoute>
        )}
      </Box>
    </ConfirmProvider>
  );
}

export default Layout;

import { LikeDislikeType } from '@constants/index';

export function likeDislikeTypeFromValue(value: Library['activeUserVote']) {
  if (!value) return LikeDislikeType.NONE;
  if (value > 0) return LikeDislikeType.LIKE;
  return LikeDislikeType.DISLIKE;
}

export function getValueFromLikeDislikeType(type: LikeDislikeType) {
  if (type === LikeDislikeType.NONE) return null;
  if (type === LikeDislikeType.LIKE) return +1;
  return -1;
}

import { AddNewPayload, GetCommentByPostIdPaginatedResponse } from './types';
import API from '../_axios';

export const getCommentByPostIdPaginated = async (id: number, page: number) => {
  const result = await API.get<GetCommentByPostIdPaginatedResponse>({
    url: `/comment/post/${id}`,
    params: { page },
  });
  return result.data;
};

export const getAllNestedCommentsByParentIdPaginated = async (id: number, page: number) => {
  const result = await API.get<GetCommentByPostIdPaginatedResponse>({
    url: `/comment/${id}`,
    params: { page },
  });
  return result.data;
};

export const addNewCommentByPostId = async (
  id: AddNewPayload['libId'],
  content: AddNewPayload['content'],
  parentId: AddNewPayload['parentId']
) => {
  const result = await API.put<GetCommentByPostIdPaginatedResponse>({
    url: `/comment/post/${id}`,
    data: { content, parentId },
  });
  return result.data;
};

export const deleteById = async (id: number) => {
  const result = await API.Delete<{ success: true }>({
    url: `/comment/${id}`,
  });
  return result.data;
};

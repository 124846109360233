import {
  CheckIfFollowedByProfileIdResponse,
  GetAnyProfileByIdResposne,
  ProfileUpdatePayload,
} from './type';
import API from '../_axios';

function getUrl(endpoint: string) {
  return `/profile${endpoint}`;
}

export const getUserOwnProfile = async () => {
  const result = await API.get<User>({ url: '/profile' });
  if (result.status === 401) {
    localStorage.clear();
    window.location.replace('/');
  }
  return result.data;
};

export const updateUserProfileData = async (data: ProfileUpdatePayload) => {
  const payload = { ...data };
  if (!payload.bio && typeof payload?.bio !== 'undefined') delete payload.bio;

  const result = await API.put<Pick<User, 'id'>>({ url: '/profile/update', data: payload });
  return result.data;
};

export const postFollowTagById = async (id: number) => {
  const result = await API.post<UserTag>({
    url: `/profile/follow-tag/${id}`,
  });
  return result.data;
};

export const getAnyProfileByUserName = async (userName: string) => {
  const result = await API.get<GetAnyProfileByIdResposne>({ url: `/profile/${userName}` });
  return result.data;
};

export const followUserById = async (id: number) => {
  const result = await API.post<GetAnyProfileByIdResposne>({ url: `/profile/follow-user/${id}` });
  return result.data;
};

export const updateUserName = async (userName: string) => {
  const result = await API.post<{ userName: string; id: string }>({
    url: '/profile/update/username',
    data: { userName },
  });
  return result.data;
};

export const verifyUserName = async (userName: string) => {
  const result = await API.get<{ isAvailable: boolean; userName: string }>({
    url: '/profile/update/username',
    params: { userName },
  });
  return result.data;
};

export const verifyIfFollowed = async (targetUserId: number) => {
  const result = await API.get<CheckIfFollowedByProfileIdResponse>({
    url: getUrl(`/verify-follower/${targetUserId}`),
  });
  return result.data;
};

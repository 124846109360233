import { CircularProgress, Stack } from '@mui/material';

interface Props {
  floating?: boolean;
}

export function Spinner({ floating }: Props) {
  return (
    <Stack
      direction='row'
      alignItems='center'
      justifyContent='center'
      sx={{
        ...(floating && {
          width: '100vw',
          height: '100dvh',
          top: 0,
          left: 0,
          position: 'fixed',
          zIndex: 29,
        }),
      }}
    >
      <CircularProgress size='10rem' />
    </Stack>
  );
}

import React from 'react';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { useAppSelector } from '@app/hooks';
import { getRootUser } from '@app/root/slice';
import { AppRoutes } from '@constants/appRoutes';
import { NotificationType } from '@constants/index';
import { useRouter } from '@hooks/useRouter';
import { isValidArray } from '@utility/array';
import { queryKeys } from 'api';
import { getAllNotifs, getUnseenCount, markNotifRead, markNotifSeen } from 'api/notif';

export type NotifClickFunction = (type: NotificationType, details: NotificationData) => void;

export function useNotificationPanel() {
  const queryClient = useQueryClient();
  const { push } = useRouter();
  const user = useAppSelector(getRootUser);

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [allowRefetch, setAllowRefetch] = React.useState(false);

  const markNotifSeenMutate = useMutation(markNotifSeen);
  const notifReadMutate = useMutation(markNotifRead);

  const unseenNotifCount = useQuery([queryKeys.getUnseenNotifsCount], () => getUnseenCount(), {
    refetchInterval: 120000, // 5 minutes
    enabled: Number.isInteger(user?.id),
  });

  const notifQuery = useQuery([queryKeys.getAllNotifs], getAllNotifs, {
    refetchInterval: 300000, // 5 minutes
    enabled: allowRefetch,
  });

  const handleNotificationsPanelOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    if (!notifQuery.isRefetching || !notifQuery.isFetching) {
      notifQuery.refetch();
    }
    if (!allowRefetch) setAllowRefetch(true);

    setTimeout(() => {
      markNotifSeenMutate.mutate(undefined, {
        onSuccess: () => {
          if (isValidArray(notifQuery.data)) {
            queryClient.setQueryData(
              [queryKeys.getAllNotifs],
              notifQuery.data?.map((item) => ({ ...item, isSeen: true }))
            );
          }
        },
      });
    }, 3000);
  };

  const handleNotificationPanelClose = () => {
    setAnchorEl(null);
  };

  const handleNotificationMarkedReadLocally = (ids: number[]) => {
    if (isValidArray(notifQuery.data)) {
      queryClient.setQueryData(
        [queryKeys.getAllNotifs],
        notifQuery.data?.map((item) => (ids.includes(item.id) ? { ...item, isRead: true } : item))
      );
    }
  };

  const handleMarkAllRead = () => {
    if (isValidArray(notifQuery.data)) {
      const allIds = notifQuery?.data?.map((item) => item.id) ?? [];
      notifReadMutate.mutate(allIds, {
        onSuccess: () => handleNotificationMarkedReadLocally(allIds),
      });
    }
  };

  const handleNewPostCommentNotifClick = (data: NewPostCommentData) => {
    push(AppRoutes.postDetail({ id: `${data.extras.libId}` }), {
      queryParams: { comment_id: `${data.extras.commentId}` },
    });
  };
  const handleCommentReplyNotifClick = (data: CommentReplyData) => {
    push(AppRoutes.postDetail({ id: `${data.extras.libId}` }), {
      queryParams: {
        comment_id: `${data.extras.replyId}`,
        parent_comment: `${data.extras.parentCommentId}`,
      },
    });
  };
  const handleNewPostPublishedNotifClick = (data: NewPostPublishedData) => {
    push(AppRoutes.postDetail({ id: `${data.targetId}` }));
  };
  const handleProfileFollowNotifClick = (data: ProfileFollowData) => {
    push(AppRoutes.profile({ id: `${data.extras.userName}` }));
  };
  const handlePostLikedNotifClick = (data: PostLikeData) => {
    push(AppRoutes.postDetail({ id: `${data.extras.libId}` }));
  };

  const handleNotificationClick: NotifClickFunction = (type, details) => {
    if (details.id) {
      notifReadMutate.mutate([details.id], {
        onSuccess: () => handleNotificationMarkedReadLocally([details.id]),
      });
    }

    handleNotificationPanelClose();

    switch (type) {
      case NotificationType.NEW_POST_COMMENT:
        return handleNewPostCommentNotifClick(details as NewPostCommentData);
      case NotificationType.COMMENT_REPLY:
        return handleCommentReplyNotifClick(details as CommentReplyData);
      case NotificationType.NEW_POST_PUBLISHED:
        return handleNewPostPublishedNotifClick(details as NewPostPublishedData);
      case NotificationType.PROFILE_FOLLOW:
        return handleProfileFollowNotifClick(details as ProfileFollowData);
      case NotificationType.POST_LIKE:
        return handlePostLikedNotifClick(details as PostLikeData);

      default:
    }
  };

  return {
    anchorEl,
    notifQuery,
    setAnchorEl,
    handleNotificationsPanelOpen,
    handleNotificationPanelClose,
    handleNotificationClick,
    handleMarkAllRead,
    unseenNotifCount,
  };
}

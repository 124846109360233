import React, { useCallback, useEffect, useState } from 'react';

import { Box, IconButton, Stack, Tooltip, Typography, css, keyframes, styled } from '@mui/material';
import { BsChevronLeft, BsChevronRight, BsX } from 'react-icons/bs';

interface GalleryProps {
  items: Array<{ src: string; id: number | string }>;
  activeId?: number | string | false;
  onClose: () => void;
  open: boolean;
}

const curtainIn = keyframes`
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const curtainOut = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-100%);
  }
`;

const GalleryOverlay = styled('div')<{ $hidden: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100dvh;
  background-color: #000000e5;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: ${({ theme }) => theme.zIndex.appBar + 10};
  animation-duration: 0.27s;
  object-fit: contain;
  z-index: 11111111111;

  ${({ $hidden }) =>
    $hidden
      ? css`
          display: none;
          animation-name: ${curtainOut};
        `
      : css`
          animation-name: ${curtainIn};
        `}
`;

const GalleryClose = styled(IconButton)`
  position: fixed;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
  svg {
    color: #fff;
  }
  :hover {
    background-color: #ffffff63;
  }
`;

const GalleryContent = styled('div')`
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  img {
    max-width: max-content;
    max-height: 80dvh;
    object-fit: scale-down !important;
  }
`;

const GalleryNavIcon = styled(IconButton)`
  cursor: pointer;

  &:not(.Mui-disabled) {
    svg {
      color: #fff;
    }
    :hover {
      background-color: #ffffff63;
    }
  }
`;

export const FullScreenGallery: React.FC<GalleryProps> = ({ open, items, activeId, onClose }) => {
  const [activeIndex, setActiveIndex] = useState<number>(0);

  useEffect(() => {
    setActiveIndex(items.findIndex((item) => item.id === activeId));
  }, [activeId, items]);

  const handlePrev = useCallback(() => {
    setActiveIndex((prevIndex) => (prevIndex - 1 + items.length) % items.length);
  }, [items.length]);

  const handleNext = useCallback(() => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % items.length);
  }, [items.length]);

  useEffect(() => {
    setActiveIndex(activeId ? items.findIndex((item) => item.id === activeId) : 0);
  }, [activeId, items]);

  useEffect(() => {
    const handleArrowKeys = (e: KeyboardEvent) => {
      if (e.key === 'ArrowLeft') {
        // Call your left arrow function here
        handlePrev();
      } else if (e.key === 'ArrowRight') {
        // Call your right arrow function here
        handleNext();
      }
    };
    window.addEventListener('keydown', handleArrowKeys);

    return () => {
      window.removeEventListener('keydown', handleArrowKeys);
    };
  }, [handleNext, handlePrev]);

  if (!items[activeIndex]?.src) return null;

  return (
    <GalleryOverlay $hidden={!open} id='gallery-overlay'>
      <Tooltip title='Close'>
        <GalleryClose onClick={onClose}>
          <BsX size={60} />
        </GalleryClose>
      </Tooltip>

      <Stack direction='row' alignItems='center' justifyContent='space-between' spacing={2}>
        <Stack alignItems='center' justifyContent='center' width='10vw'>
          <Tooltip title='Previous'>
            <GalleryNavIcon onClick={handlePrev} disabled={items.length <= 1}>
              <BsChevronLeft size={60} />
            </GalleryNavIcon>
          </Tooltip>
        </Stack>
        <Box width='80vw'>
          <GalleryContent>
            <img src={items[activeIndex].src} alt={`Gallery Item ${items[activeIndex].id}`} />
          </GalleryContent>
          <Typography
            sx={{
              textAlign: 'center',
              fontSize: '0.9rem',
              bgcolor: '#ffffffc5',
              borderRadius: 3,
              width: 'max-content',
              px: 1,
              float: 'right',
            }}
          >
            {activeIndex + 1}/{items.length}
          </Typography>
        </Box>
        <Stack alignItems='center' justifyContent='center' width='10vw'>
          <Tooltip title='Next'>
            <GalleryNavIcon onClick={handleNext} disabled={items.length <= 1}>
              <BsChevronRight size={60} />
            </GalleryNavIcon>
          </Tooltip>
        </Stack>
      </Stack>
    </GalleryOverlay>
  );
};

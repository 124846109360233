import React, { PropsWithChildren } from 'react';

import {
  Box,
  IconButton,
  IconButtonProps,
  LinearProgress,
  Modal as MuiModal,
  Stack,
  colors,
  styled,
} from '@mui/material';
import Scrollbars from 'react-custom-scrollbars';
import { BsXLg } from 'react-icons/bs';

interface ModalProps {
  open?: boolean;
  CloseButtonProps?: IconButtonProps;
  width?: string;
  hideCloseBtn?: boolean;
  unmountOnClose?: boolean;
  loading?: boolean;
  disablePadding?: boolean;
  height?: string;
}

export function Modal({
  open = true,
  children,
  width,
  CloseButtonProps,
  hideCloseBtn,
  unmountOnClose,
  loading,
  disablePadding,
  height,
}: PropsWithChildren<ModalProps>) {
  return (
    <MuiModal
      keepMounted={!unmountOnClose}
      open={open}
      slotProps={{ backdrop: { sx: { bgcolor: '#e3f2fdf2' } } }}
    >
      <>
        {loading && (
          <LinearProgress
            sx={{ width: '100%', position: 'fixed', top: 0, left: 0, zIndex: 11111 }}
          />
        )}

        <Stack
          direction='row'
          alignItems='center'
          justifyContent='center'
          sx={{ height: '90dvh', mt: '5dvh' }}
        >
          <Stack
            sx={{
              bgcolor: (theme) => theme.palette.common.white,
              width: width ?? '30rem',
              minHeight: '10rem',
              maxHeight: height ?? '25em',
              borderRadius: '1rem',
              boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
              position: 'relative',
              height: '100%',
            }}
          >
            <Scrollbars>
              <Box sx={{ p: disablePadding ? 0 : 4, pt: 2 }}>
                {!hideCloseBtn && (
                  <CloseButtonStyled {...CloseButtonProps}>
                    <BsXLg />
                  </CloseButtonStyled>
                )}
                <Box height='100%'>{children}</Box>
                {loading && (
                  <Stack
                    sx={{
                      height: '100%',
                      width: '100%',
                      zIndex: 1,
                      top: 0,
                      left: 0,
                      position: 'absolute',
                      backgroundColor: '#ffffffc6',
                      borderRadius: '1rem',
                    }}
                  />
                )}
              </Box>
            </Scrollbars>
          </Stack>
        </Stack>
      </>
    </MuiModal>
  );
}

const CloseButtonStyled = styled(IconButton)`
  background-color: ${colors.red[300]};
  color: ${({ theme }) => theme.palette.common.white};
  width: 3.6rem;
  height: 3.6rem;
  position: fixed;
  right: 2rem;
  top: 2rem;
  z-index: 2;

  &:hover {
    background-color: ${colors.red[800]};
  }
`;

import React, { useCallback, useEffect, useState } from 'react';

import { Box, BoxProps, styled } from '@mui/material';
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs';

const CollageContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  overflowX: 'scroll',
  overflowY: 'hidden',
  whiteSpace: 'nowrap',
  scrollbarWidth: 'thin',
  scrollbarColor: 'transparent transparent',
  overscrollBehaviorX: 'none',

  '&::-webkit-scrollbar': {
    width: '12px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'transparent',
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: 'transparent',
  },
}));

const CollageImage = styled('img')(({ theme }) => ({
  marginRight: '10px', // Add margin between images
  height: '100%',
}));

const ScrollButton = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  background: 'rgba(255, 255, 255, 0.5)',
  padding: '10px',
  borderRadius: '50%',
  cursor: 'pointer',
  height: '2.6rem',
  width: '2.6rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 23,

  '&:hover': {
    background: theme.palette.common.white,
  },
}));

type ImageSlide = {
  id: string | number;
  src: string;
};

interface Props extends BoxProps {
  images: ImageSlide[];
  onItemClick?: (itemId: string | number) => void;
}

export const HorizontalCollage = ({ images, onItemClick, ...rest }: Props) => {
  const [scrollX, setScrollX] = useState(0);
  const [hasScrollLeft, setHasScrollLeft] = useState(false);
  const [hasScrollRight, setHasScrollRight] = useState(false);
  const collageRef = React.createRef<HTMLDivElement>();

  useEffect(() => {
    const collageContainer = collageRef.current;
    if (collageContainer) {
      setHasScrollLeft(collageContainer.scrollLeft > 0);
      setHasScrollRight(
        collageContainer.scrollLeft < collageContainer.scrollWidth - collageContainer.clientWidth
      );
    }
  }, [scrollX, collageRef]);

  const handleScrollLeft = useCallback(() => {
    const collageContainer = collageRef.current;
    if (collageContainer) {
      collageContainer.scrollBy({ left: -collageContainer.clientWidth, behavior: 'smooth' });
    }
  }, [collageRef]);

  const handleScrollRight = useCallback(() => {
    const collageContainer = collageRef.current;
    if (collageContainer) {
      collageContainer.scrollBy({ left: collageContainer.clientWidth, behavior: 'smooth' });
    }
  }, [collageRef]);

  useEffect(() => {
    const handleArrowKeys = (e: KeyboardEvent) => {
      if (e.key === 'ArrowLeft') {
        // Call your left arrow function here
        handleScrollLeft();
      } else if (e.key === 'ArrowRight') {
        // Call your right arrow function here
        handleScrollRight();
      }
    };
    window.addEventListener('keydown', handleArrowKeys);

    return () => {
      window.removeEventListener('keydown', handleArrowKeys);
    };
  }, [handleScrollLeft, handleScrollRight]);

  return (
    <Box sx={{ position: 'relative' }}>
      <CollageContainer
        {...rest}
        ref={collageRef}
        onScroll={(e) => {
          setScrollX(e.currentTarget.scrollLeft);
        }}
        id='collage-container-horizontal'
      >
        {hasScrollLeft && (
          <ScrollButton onClick={handleScrollLeft} sx={{ left: 10 }}>
            <BsArrowLeft />
          </ScrollButton>
        )}
        {images.map((image) => (
          <Box key={image.id} sx={{ height: rest.height }} onClick={() => onItemClick?.(image.id)}>
            <CollageImage
              src={image.src}
              alt='Horizontal collage'
              loading='lazy'
              sx={{ cursor: 'zoom-in' }}
            />
          </Box>
        ))}

        {hasScrollRight && (
          <ScrollButton onClick={handleScrollRight} sx={{ right: 10 }}>
            <BsArrowRight />
          </ScrollButton>
        )}
      </CollageContainer>
    </Box>
  );
};

import React, { useState } from 'react';

import { styled } from '@mui/material';

interface Props {
  text: string;
  length: number;
  hidePrefix?: boolean;
}

export const TextTruncateWithoutExpand = ({ text, length, hidePrefix }: Props) => {
  const leaveUntruncated = text?.length <= length;
  const truncatedText = leaveUntruncated ? text : `${hidePrefix ? '' : text?.slice(0, length)}...`;

  return <span>{truncatedText}</span>;
};

export const TextTruncate = ({ text, length, hidePrefix }: Props) => {
  const [isTruncated, setIsTruncated] = useState(true);

  const toggleTruncate = () => {
    setIsTruncated(!isTruncated);
  };

  const leaveUntruncated = text?.length <= length;

  return (
    <div>
      {isTruncated ? (
        <div>
          {!leaveUntruncated ? text?.slice(0, length) + '...' : text}
          {!leaveUntruncated && !hidePrefix && (
            <SpanStyled onClick={toggleTruncate}>(Show more)</SpanStyled>
          )}
        </div>
      ) : (
        <div>
          {text}
          {!hidePrefix && <SpanStyled onClick={toggleTruncate}>(Show less)</SpanStyled>}
        </div>
      )}
    </div>
  );
};

const SpanStyled = styled('span')`
  padding-left: 0.2rem;
  font-weight: 500;
  cursor: pointer;
  font-style: italic;
`;

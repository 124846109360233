import React, { ChangeEvent, useCallback, useState } from 'react';

import {
  Avatar,
  Box,
  Chip,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  Link as MuiLink,
  Stack,
  TextField,
  Typography,
  colors,
  lighten,
  styled,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { Scrollbars } from 'react-custom-scrollbars';
import { BsArrowRight, BsSearch } from 'react-icons/bs';
import { Link } from 'react-router-dom';

import { useAppDispatch } from '@app/hooks';
import { rootActions } from '@app/root/slice';
import { AppRoutes } from '@constants/appRoutes';
import { pricingModelMappedNames } from '@constants/index';
import { isValidArray } from '@utility/array';
import { getPostByKeyword, queryKeys } from 'api';
import { GetByKeywordResponse } from 'api/post/types';

import { EmptyState } from '..';

const TextFieldStyled = styled(TextField)(({ theme }) => ({
  padding: '0 0.5rem',
}));

const ListItemAvatarStack = styled(Stack)(({ theme }) => ({
  transition: 'all 0.27s linear',
  '.endArrow': {
    display: 'none',
    color: colors.grey[500],
  },
  '&:hover': {
    background: lighten(colors.blue[100], 0.6),
    '.endArrow': {
      display: 'flex',
      '&:hover': {
        color: colors.grey[700],
      },
    },
  },
}));

const MuiChipStyled = styled(Chip)(({ theme }) => ({
  padding: 0,
  height: '16px',
  width: 'max-content',
  backgroundColor: 'transparent',
  color: 'GrayText',

  '& .MuiChip-label': {
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

const FeatureChip = styled(Chip)(() => ({
  height: '1.4rem',
  width: 'max-content',
  backgroundColor: 'transparent',
  color: 'GrayText',
  fontSize: '0.75rem',

  '& .MuiChip-label': {
    paddingLeft: '0.4rem',
    paddingRight: '0.4rem',
  },
}));

interface SearchModalProps {
  open: boolean;
  onClose: () => void;
}

type ListHeaderProps = {
  text: string;
};
type ListItemWithAvatarProps = GetByKeywordResponse[number];

const SearchModal = ({ open }: SearchModalProps) => {
  const dispatch = useAppDispatch();
  const [searchTerm, setSearchTerm] = useState('');

  const searchQ = useQuery(
    [queryKeys.searchPost, searchTerm],
    () => getPostByKeyword(searchTerm, 1),
    { enabled: !!searchTerm && searchTerm.length >= 3 }
  );

  const onClose = () => {
    dispatch(rootActions.setActiveModal(null));
  };

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target) return;
    setSearchTerm(event.target.value);
  };

  const handleClearSearch = () => {
    setSearchTerm('');
  };

  const handleDialogClose = () => {
    onClose();
    handleClearSearch();
  };

  const getIsSearching = useCallback(() => !!searchTerm.trim(), [searchTerm]);

  return (
    <Dialog keepMounted={false} open={open} onClose={handleDialogClose} maxWidth='md'>
      <DialogContent sx={{ p: 0, bgcolor: 'white', width: '45em' }}>
        <Stack direction='row' alignItems='center' sx={{ p: 1 }}>
          <IconButton size='small'>
            <BsSearch />
          </IconButton>
          <TextFieldStyled
            autoFocus
            fullWidth
            value={searchTerm}
            onChange={handleSearchChange}
            variant='standard'
            InputProps={{ disableUnderline: true, autoFocus: true }}
            placeholder='Search'
            autoComplete='off'
          />
        </Stack>
        {searchQ.isFetching && <LinearProgress />}
        <Divider />
        <Box sx={{ height: '80dvh' }}>
          <Scrollbars>
            {getIsSearching() ? (
              <EmptyState
                show={!isValidArray(searchQ.data) && searchQ.isSuccess}
                description='Try a different keyword'
                title='No items found'
                icon='bs'
              >
                <Box sx={{ mb: 1 }}>
                  <List disablePadding>
                    <ListHeader text='Posts' />
                    {searchQ.data?.map((item) => (
                      <ListItem key={item.id} disableGutters disablePadding sx={{ mb: 0.6 }}>
                        <ListItemWithAvatar {...item} />
                      </ListItem>
                    ))}
                  </List>
                </Box>
              </EmptyState>
            ) : (
              <Stack alignItems='center' justifyContent='center' height='100%'>
                <IconButton size='large'>
                  <BsSearch size={50} />
                </IconButton>
                <Typography variant='h5' mt={2}>
                  Search a keyword
                </Typography>
              </Stack>
            )}
          </Scrollbars>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default SearchModal;

function ListHeader({ text }: ListHeaderProps) {
  return (
    <ListItem>
      <ListItemText
        primary={text}
        primaryTypographyProps={{ variant: 'body2', fontSize: '0.9rem', color: 'GrayText' }}
      />
    </ListItem>
  );
}

function ListItemWithAvatar({
  id,
  name,
  thumbnailUrl,
  tagline,
  LibraryTag,
  isOpenSource,
  pricingModel,
}: ListItemWithAvatarProps) {
  return (
    <MuiLink
      width='100%'
      underline='none'
      to={AppRoutes.postDetail({ id: `${id}` })}
      component={Link}
      target='_blank'
      rel='noreferrer noopener'
    >
      <ListItemAvatarStack
        id={`search-result-item-library-${id}`}
        spacing={1.8}
        direction='row'
        alignItems='center'
        justifyContent='flex-start'
        sx={{ width: '100%', px: 2, py: 1, cursor: 'pointer' }}
        aria-labelledby='search-result-item'
      >
        <Avatar sx={{ width: '4rem', height: '4rem' }} src={thumbnailUrl as string}>
          {name}
        </Avatar>
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
          sx={{ width: '100%' }}
        >
          <Box sx={{ width: '100%' }}>
            <Stack direction='row' alignItems='center' justifyContent='flex-start'>
              <Typography sx={{ fontSize: '1.1rem', fontWeight: 600 }}>{name}</Typography>
              <Stack spacing={1} direction='row' alignItems='center' sx={{ ml: 2 }}>
                <FeatureChip
                  variant='outlined'
                  label={
                    pricingModelMappedNames[pricingModel as keyof typeof pricingModelMappedNames]
                  }
                  sx={{ borderColor: colors.blue[600], color: colors.blue[600] }}
                />{' '}
                {isOpenSource && (
                  <FeatureChip
                    variant='outlined'
                    label='Open Source'
                    sx={{ borderColor: colors.brown[600], color: colors.brown[600] }}
                  />
                )}
              </Stack>
            </Stack>
            <Typography sx={{ fontSize: '0.9rem', color: 'GrayText', mt: 0.6 }}>
              {tagline}
            </Typography>

            {isValidArray(LibraryTag) && (
              <Stack direction='row' alignItems='center' justifyContent='flex-start' sx={{ mt: 1 }}>
                {LibraryTag.map((item) => (
                  <MuiChipStyled
                    sx={{ fontSize: '0.8rem', mr: 1 }}
                    label={`#${item.tag.name}`}
                    key={item.id}
                  />
                ))}
              </Stack>
            )}
          </Box>
          <IconButton size='small' className='endArrow'>
            <BsArrowRight />
          </IconButton>
        </Stack>
      </ListItemAvatarStack>
    </MuiLink>
  );
}

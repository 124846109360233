import {
  NavigateOptions as NOptions,
  To,
  URLSearchParamsInit,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';

type SetSearchParamsCb = (prev: URLSearchParams) => URLSearchParamsInit;

type NavigateOptions = {
  queryParams?: Record<string, string>;
  options?: NOptions;
};

type UseRouter = {
  push: (to: To, options?: NavigateOptions) => void;
  replace: (to: To, options?: NavigateOptions) => void;
  parseSearchParams: () => Record<string, string>;
  getQueryParam: (key: string) => string | undefined;
  modifySearchParams: (newParams: Record<string, string> | SetSearchParamsCb) => void;
  navigateTo: (pathname: string, opts?: NavigateOptions) => void;
  searchParams: URLSearchParams;
};

export function useRouter(): UseRouter {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  // Function to parse the query string and return an object of key-value pairs.
  function parseSearchParams(): Record<string, string> {
    const params = new URLSearchParams(searchParams);
    const parsedParams: Record<string, string> = {};
    for (const [key, value] of params.entries()) {
      parsedParams[key] = value;
    }
    return parsedParams;
  }

  // Function to get the value of a single query parameter.
  function getQueryParam(key: string): string | undefined {
    return searchParams.get(key) ?? undefined;
  }

  // Function to navigate to a new route, optionally with query parameters.
  function navigateTo(pathname: string, opts?: NavigateOptions) {
    navigate(
      {
        pathname,
        search: opts?.queryParams ? new URLSearchParams(opts.queryParams).toString() : '',
      },
      opts?.options
    );
  }

  // Function to modify the query parameters for the current route.
  function modifySearchParams(newParams: Record<string, string> | SetSearchParamsCb) {
    setSearchParams(newParams);
  }

  return {
    parseSearchParams,
    getQueryParam,
    navigateTo,
    modifySearchParams,
    searchParams,
    push: (to, options) => navigate(to, { preventScrollReset: true, ...options, replace: false }),
    replace: (to, options) => navigate(to, { preventScrollReset: true, ...options, replace: true }),
  };
}

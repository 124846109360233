import * as React from 'react';

import { Box, ClickAwayListener, colors, styled } from '@mui/material';
import Popover from '@mui/material/Popover';

type Props = React.PropsWithChildren<{ popperElement: React.ReactNode; onClickEvent?: boolean }>;

export function Popper({ children, popperElement, onClickEvent }: Props) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = (event: any) => {
    if (!anchorEl) return;
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <ClickAwayListener onClickAway={handlePopoverClose}>
      <Box>
        {React.cloneElement(React.Children.only(children) as React.ReactElement, {
          onMouseEnter: handlePopoverOpen,
          ...(onClickEvent && {
            onMouseEnter: undefined,
            onClick: handlePopoverOpen,
          }),
          ...(open && { className: 'popper-active', style: { display: 'block' } }),
        })}
        <Popover
          id='mouse-over-popover'
          sx={{}}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
          slotProps={{
            paper: {
              sx: {
                bgcolor: 'white',
                borderRadius: '0.8rem',
                boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
              },
            },
          }}
        >
          <PopperBox>{popperElement}</PopperBox>
        </Popover>
      </Box>
    </ClickAwayListener>
  );
}

const PopperBox = styled(Box)`
  .MuiButton-root {
    height: 2.2rem;
    padding: 0 1.4rem;
    width: 100%;
    justify-content: flex-start;
    color: ${(props) => props.theme.palette.text.secondary};
    font-weight: 400;
    font-size: 1rem;
    text-transform: none;
    border-radius: 0;

    svg {
      font-size: 1.2rem;
      margin-right: 3px;
      color: ${(props) => props.theme.palette.text.secondary};
    }

    &:hover {
      color: ${colors.blue[700]};
      svg {
        color: ${colors.blue[700]};
      }
    }
  }
`;

export const setSession = (token: string) => {
  localStorage.setItem('frulow-access', token);
};
export const getSessionToken = () => {
  return localStorage.getItem('frulow-access');
};

export const clearAllSessionTokens = () => {
  localStorage.clear();
  sessionStorage.clear();
  window.location.replace('/');
};

export const isPaymentDisabled = () => {
  return process.env.REACT_APP_DISABLE_PAYMENT;
};

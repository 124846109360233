import React from 'react';

import { CircularProgress, Stack, SxProps, styled } from '@mui/material';

const StyledDiv = styled(Stack)(({ theme }) => ({
  height: '100dvh',
  width: '100vw',
  zIndex: theme.zIndex.appBar + 1,
  background: '#e0e0ecb8',
  position: 'fixed',
  top: 0,
  left: 0,
}));

interface Props {
  sx?: SxProps;
}

export function DisableView({ sx }: Props) {
  return (
    <StyledDiv alignItems='center' justifyContent='center' sx={sx}>
      <CircularProgress size={40} />
    </StyledDiv>
  );
}

import React, { useCallback, useState } from 'react';

import { Box, Button, colors, styled } from '@mui/material';
import { GoogleOAuthProvider, useGoogleLogin, useGoogleOneTapLogin } from '@react-oauth/google';
import { BsGoogle } from 'react-icons/bs';

import { useToast } from '@hooks/useToast';
import { setSession } from '@utility/session';
import { loginWithGoogle } from 'api';

import { DisableView } from '..';

function ProvideGoogleLogin() {
  const { promiseToast, errorToast } = useToast();
  const [isLoading, setIsLoading] = useState(false);

  const handleGoogleLoginError = useCallback(() => {
    errorToast('Error authenticating user. Please try again.');
  }, [errorToast]);

  const handleAcceptedGoogleAuth = async (res: any) => {
    if (isLoading) return;
    setIsLoading(true);
    try {
      const accessToken = res.code ?? res.credential;
      // Fetch user information to get the ID token
      await promiseToast(loginWithGoogle(accessToken, !!res.code), {
        error: (e) => e?.message ?? 'An error occurred while logging in',
        loading: 'Authenticating...',
        success: (data) => {
          setSession(data.token);
          window.location.replace('/');
          return 'Successfully authenticated. Setting up things...';
        },
      });
    } catch (error) {
      console.log('Error login in with google:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const googleLogin = useGoogleLogin({
    onSuccess: handleAcceptedGoogleAuth,
    onError: handleGoogleLoginError,
    scope: 'openid',
    flow: 'auth-code',
    // ux_mode: 'popup',
  });

  useGoogleOneTapLogin({
    onSuccess: handleAcceptedGoogleAuth,
    onError: handleGoogleLoginError,
    disabled: isLoading,
  } as any);

  const _handleLogin = useCallback(() => {
    googleLogin();
  }, [googleLogin]);

  return (
    <RootBox width='100%'>
      <GoogleLoginButton
        startIcon={<BsGoogle />}
        variant='outlined'
        fullWidth
        disableElevation
        onClick={_handleLogin}
      >
        Google
      </GoogleLoginButton>
      {isLoading && <DisableView />}
    </RootBox>
  );
}

type Props = {
  variant?: 'hidden';
};

export const GoogleLoginProvider = ({ variant }: Props) => {
  return (
    <Box sx={{ ...(variant === 'hidden' && { visibility: 'hidden' }) }}>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID as string}>
        <ProvideGoogleLogin />
      </GoogleOAuthProvider>
    </Box>
  );
};

export default GoogleLoginProvider;

const RootBox = styled(Box)`
  & div[role='button'] {
    padding: 1rem;
  }
`;

const GoogleLoginButton = styled(Button)`
  border-radius: 0.6rem;
  border-color: ${colors.blueGrey[200]};
  color: #fe432ae9;
  text-transform: none;
  svg {
    color: #fe432ae9;
  }
`;

import { PropsWithChildren } from 'react';

import { Stack, StackProps } from '@mui/material';

export const StackRow = ({ children, ...rest }: PropsWithChildren & StackProps) => {
  return (
    <Stack direction='row' alignItems='center' justifyContent='flex-start' {...rest}>
      {children}
    </Stack>
  );
};

import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  useInfiniteQuery,
} from '@tanstack/react-query';

const useInfiniteQueryWithPagination = (
  queryKey: QueryKey,
  fetchData: QueryFunction<any>,
  initialPage = 1,
  infiniteQueryOptions: UseInfiniteQueryOptions<any, any, any> = {}
) => {
  const [page, setPage] = useState(initialPage);

  const {
    data,
    isInitialLoading,
    isSuccess,
    isError,
    fetchNextPage,
    isFetchingNextPage,
    remove,
    hasNextPage,
    refetch: directRefetch,
  } = useInfiniteQuery(queryKey, (data) => fetchData(data), {
    keepPreviousData: true,
    ...infiniteQueryOptions,
    enabled: !!(queryKey && (infiniteQueryOptions.enabled ?? true)),
    getNextPageParam: (lastPage) => {
      if (lastPage?.length <= 0) return null;
      return page + 1;
    },
    refetchOnMount: true,
    retry: 2,
  });

  const loadMore = useCallback(() => {
    if (isFetchingNextPage || !hasNextPage || isError) return;

    fetchNextPage({ pageParam: page + 1 });
    setPage((prev) => prev + 1);
  }, [fetchNextPage, hasNextPage, isError, isFetchingNextPage, page]);

  const pageData = useMemo(() => {
    return {
      count: data?.pages?.reduce((prev, curr) => prev + curr.length, 0) ?? 0,
      items: data?.pages?.reduce((prev, curr) => [...prev, ...curr], []) ?? [],
    };
  }, [data?.pages]);

  const refetch = useCallback(() => {
    setPage(1);
    remove();
  }, [remove]);

  useEffect(() => {
    if (data?.pageParams?.length) {
      setPage((data.pageParams[data.pageParams.length - 1] as any as number) ?? initialPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    pageData,
    page,
    isInitialLoading,
    isSuccess,
    isError,
    isFetchingNextPage,
    loadMore,
    fetchNextPage,
    refetch,
    hasNextPage,
    rawData: data,
    forceRefetch: directRefetch,
  };
};

export default useInfiniteQueryWithPagination;

import { LikeDislikeType } from '@constants/index';
import { routePrefix } from 'api/routePrefix';

import { GetPostReactionsById, LikeDislikeCreateUpdateResponse } from './types';
import API from '../_axios';

function getUrl(endpoint: string) {
  return `/post-reaction${endpoint}`;
}

export const likeDislikeCreateUpdate = async (postId: number, type: LikeDislikeType) => {
  const result = await API.post<LikeDislikeCreateUpdateResponse>({
    url: [routePrefix.postReaction, 'like-dislike', postId].join('/'),
    data: { type },
  });
  return result.data;
};
export const getPostReactions = async (postId: number) => {
  const result = await API.get<GetPostReactionsById>({
    url: getUrl(`/${postId}`),
  });
  return result.data;
};

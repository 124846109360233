import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { AppState } from '@app/store';

export enum ModalTypes {
  'AUTH' = 'AUTH',
  'PHOTO_UPLOADER' = 'PHOTO_UPLOADER',
  'EMAIL_VERIFY_OTP_MODAL' = 'EMAIL_VERIFY_OTP_MODAL',
  'PROFILE' = 'PROFILE',
  'SEARCH' = 'SEARCH',
  'REPORT' = 'REPORT',
  'CREATE_COLLECTION' = 'CREATE_COLLECTION',
  'ADD_TO_COLLECTION' = 'ADD_TO_COLLECTION',
  'USER_NAME_SELECTOR' = 'USER_NAME_SELECTOR',
}

type ModalConfig = {
  disableClose?: boolean;
  redirectOnCloseTo?: string;
  objectType?: ObjectTypes;
  objectId?: number;
  extras?: Record<string, unknown>;
};

type InitialState = {
  activeModal: null | ModalTypes;
  user: null | User;
  modalConfig: null | ModalConfig;
};

const initialState: InitialState = {
  activeModal: null,
  user: null,
  modalConfig: null,
};

const rootSlice = createSlice({
  name: 'root',
  initialState,
  reducers: {
    setModalConfig: (state, { payload }: PayloadAction<InitialState['modalConfig']>) => {
      state.modalConfig = payload;
    },
    setActiveModal: (state, { payload }: PayloadAction<InitialState['activeModal']>) => {
      state.activeModal = payload;
      if (!payload) state.modalConfig = null;
    },
    checkUserValidity: (state) => {
      const payload = state.user;

      if (!payload?.firstName || !payload?.lastName) {
        state.activeModal = ModalTypes.PROFILE;
        state.modalConfig = {
          disableClose: true,
          extras: {
            isNameErrored: true,
          },
        };
      } else if (!payload?.emailVerified) state.activeModal = ModalTypes.EMAIL_VERIFY_OTP_MODAL;
      else if (!payload?.profilePicUrl) state.activeModal = ModalTypes.PHOTO_UPLOADER;
      else if (!payload?.userNameSet) state.activeModal = ModalTypes.USER_NAME_SELECTOR;
      else {
        state.activeModal = null;
        state.modalConfig = null;
      }
    },
    setUser: (state, { payload }: PayloadAction<InitialState['user']>) => {
      state.user = payload;
    },
    resetState: (state) => {
      // NOSONAR
      state = initialState;
    },
  },
});

/** Selectors */
export const getRootActiveModal = ({ root: { activeModal } }: AppState) => ({
  isAuth: activeModal === ModalTypes.AUTH,
  isPhotoUpload: activeModal === ModalTypes.PHOTO_UPLOADER,
  isEmailOtpVerify: activeModal === ModalTypes.EMAIL_VERIFY_OTP_MODAL,
  isProfile: activeModal === ModalTypes.PROFILE,
  isSearch: activeModal === ModalTypes.SEARCH,
  isReport: activeModal === ModalTypes.REPORT,
  isCreateCollection: activeModal === ModalTypes.CREATE_COLLECTION,
  isAddToCollection: activeModal === ModalTypes.ADD_TO_COLLECTION,
  isUserNameSelect: activeModal === ModalTypes.USER_NAME_SELECTOR,
});
export const getRootUser = (state: AppState) => state.root.user;
export const getModalConfig = (state: AppState) => state.root.modalConfig;

export const rootActions = rootSlice.actions;
export default rootSlice.reducer;

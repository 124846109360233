import { SuccessOnlyReponse } from './types';
import API from '../_axios';

function getUrl(endpoint: string) {
  return `/notif${endpoint}`;
}

export const getAllNotifs = async () => {
  const result = await API.get<NotificationData[]>({ url: getUrl('/') });
  return result.data;
};
export const getUnseenCount = async () => {
  const result = await API.get<{ unseenCount: number }>({ url: getUrl('/unseen-count') });
  return result.data;
};

export const markNotifRead = async (ids: number[]) => {
  const result = await API.post<SuccessOnlyReponse>({ url: getUrl('/read'), data: { id: ids } });
  return result.data;
};

export const markNotifSeen = async () => {
  const result = await API.post<SuccessOnlyReponse>({ url: getUrl('/seen') });
  return result.data;
};

import React, { useCallback } from 'react';

import {
  Avatar,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  Stack,
  Typography,
  colors,
  styled,
} from '@mui/material';
import { get } from 'lodash';

import { NotificationType } from '@constants/index';
import { getShortestDuration } from '@utility/dates';
import { numberToWords } from '@utility/numberToWords';

import { NotifClickFunction } from './useNotificationPanel';
import { TextTruncateWithoutExpand } from '..';

const concatenateStringWithCount = (inputString: string, count: number): string => {
  if (count > 0) {
    return `${inputString} & ${numberToWords(count.toString(), {
      decimalPlaces: 0,
      useShortForm: true,
    })} Others`;
  } else {
    return inputString;
  }
};

export interface NotificationItemsProps {
  data: NotificationData[];
  onNotifClick: NotifClickFunction;
}

function NotificationItems({ data, onNotifClick }: NotificationItemsProps) {
  const getNotifContent = useCallback((notifyType: NotificationType, content: NotificationData) => {
    switch (notifyType) {
      case NotificationType.NEW_POST_COMMENT:
        return <NewCommentNotif {...(content as NewPostCommentData)} />;
      case NotificationType.COMMENT_REPLY:
        return <CommentReplyNotification {...(content as CommentReplyData)} />;
      case NotificationType.POST_LIKE:
        return <PostLikeNotif {...(content as PostLikeData)} />;
      case NotificationType.PROFILE_FOLLOW:
        return <ProfileFollowNotif {...(content as ProfileFollowData)} />;
      case NotificationType.NEW_POST_PUBLISHED:
        return <NewPostPublishedNotif {...(content as NewPostPublishedData)} />;
      default:
        return null;
    }
  }, []);

  return (
    <List sx={{ width: '100%', py: 0 }}>
      {data.length <= 0 && (
        <Typography
          variant='body2'
          textAlign='center'
          sx={{ py: 2, fontWeight: 600, color: 'GrayText' }}
        >
          No new notification
          <Typography variant='caption' sx={{ display: 'block' }}>
            You will see all your notifications here.
          </Typography>
        </Typography>
      )}

      {data.map((item) => (
        <>
          <NotifListItem
            key={item.id}
            onClick={() => onNotifClick(item.type, item)}
            alignItems='flex-start'
            sx={({ palette }) => ({
              py: 1.5,
              ...(!item.isRead && { bgcolor: palette.notification.highlight }),
            })}
          >
            <ListItemAvatar sx={{ mt: 0 }}>
              <Avatar
                variant='rounded'
                alt='Remy Sharp'
                src={
                  get(item, 'extras.profilePicUrl') ??
                  get(item, 'extras.thumbnailUrl') ??
                  'global/logo_512x512.png'
                }
                sx={{ maxWidth: 30, maxHeight: 30 }}
              />
              <Typography
                textAlign='center'
                variant='body2'
                sx={{ fontSize: '0.65rem', color: 'GrayText', mr: 2, mt: 0.4 }}
              >
                {getShortestDuration({ date: item.createdAt })}
              </Typography>
            </ListItemAvatar>

            <Stack width='100%'>{getNotifContent(item.type, item)}</Stack>
          </NotifListItem>
          <Divider variant='inset' component='li' light />
        </>
      ))}
    </List>
  );
}

export default NotificationItems;

function NewCommentNotif(data: NewPostCommentData) {
  return (
    <NotifStack direction='column'>
      <Typography variant='body2' sx={{ color: 'GrayText' }}>
        <Typography data-disable-ml data-strong fontSize='0.9rem'>
          {data.extras.fullName}{' '}
        </Typography>
        commented on library{' '}
        <Typography variant='body2' data-disable-ml data-strong sx={{}}>
          <TextTruncateWithoutExpand text={data.extras.name} length={50} />
        </Typography>
      </Typography>
      <Typography
        variant='body2'
        sx={{
          color: 'textPrimary',
          fontWeight: 700,
          p: '0.5rem',
          width: '100%',
          bgcolor: colors.blueGrey[50],
          borderRadius: 1,
          my: 0.6,
        }}
      >
        <TextTruncateWithoutExpand text={data.extras.content} length={100} />
      </Typography>
    </NotifStack>
  );
}
function CommentReplyNotification(data: CommentReplyData) {
  return (
    <NotifStack direction='column'>
      <Typography variant='body2' sx={{ color: 'GrayText' }}>
        <Typography data-disable-ml data-strong fontSize='0.9rem'>
          {data.extras.fullName}{' '}
        </Typography>
        replied to your comment on library{' '}
        <Typography variant='body2' data-disable-ml data-strong sx={{}}>
          <TextTruncateWithoutExpand text={data.extras.name} length={50} />
        </Typography>
      </Typography>
      <Typography
        variant='body2'
        sx={{
          color: 'textPrimary',
          fontWeight: 700,
          p: '0.5rem',
          width: '100%',
          bgcolor: colors.blueGrey[50],
          borderRadius: 1,
          my: 0.6,
        }}
      >
        <TextTruncateWithoutExpand text={data.extras.content} length={100} />
      </Typography>
    </NotifStack>
  );
}

// Improved PostLikeNotif function
function PostLikeNotif(data: PostLikeData) {
  const { fullName, likesCount, name } = data.extras;

  return (
    <NotifStack direction='column'>
      <Typography variant='body2' sx={{ color: 'GrayText' }}>
        <Typography data-strong data-disable-ml variant='body2'>
          {concatenateStringWithCount(fullName, +likesCount - 1)}
        </Typography>{' '}
        liked your library
      </Typography>
      <Typography variant='body2' data-strong data-disable-ml sx={{}}>
        <TextTruncateWithoutExpand text={name} length={50} />
      </Typography>
    </NotifStack>
  );
}

// Improved ProfileFollowNotif function
function ProfileFollowNotif(data: ProfileFollowData) {
  const { fullName } = data.extras;

  return (
    <NotifStack direction='column'>
      <Typography variant='body2' sx={{ color: 'GrayText' }}>
        <Typography data-strong data-disable-ml variant='body2' sx={{}}>
          {fullName}
        </Typography>{' '}
        started following you. Click to view their profile.
      </Typography>
    </NotifStack>
  );
}

function NewPostPublishedNotif(data: NewPostPublishedData) {
  const { name, tagline } = data.extras;

  return (
    <NotifStack direction='column'>
      <Typography variant='body2' sx={{ color: 'GrayText', mb: 0.3 }}>
        You have successfully published your library 🤟🔥
      </Typography>
      <Typography data-strong data-disable-ml>
        {name} —{' '}
        <Typography data-strong data-disable-ml>
          {tagline}
        </Typography>
      </Typography>{' '}
    </NotifStack>
  );
}

const NotifStack = styled(Stack)`
  .MuiTypography-root {
    display: inline-block;
    word-break: keep-all;
    font-size: 14px;

    &[data-strong='true'] {
      color: #000000f6;
      display: inline;
      font-weight: 500;
      &:not(:first-child) {
        margin-left: 0.3rem;
      }
    }

    &[data-disable-ml='true'] {
      margin-left: 0 !important;
    }
  }
`;

const NotifListItem = styled(ListItem)`
  cursor: pointer;
  &:hover {
    background: ${colors.blue[50]};
  }

  & .small {
    font-size: 12px;
    color: ${(props) => props.theme.palette.text.secondary};
  }
`;

import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';

import { Avatar, AvatarProps } from '@mui/material';

import useIntersection from '@hooks/useIntersection';
import PlaceholderSrc from 'assets/images/placeholders/1.jpeg';

interface LazyImageProps extends AvatarProps {
  src: string;
  alt: string;
  avatar?: boolean;
}

export const Image: React.FC<PropsWithChildren<LazyImageProps>> = ({
  children,
  avatar,
  src,
  alt,
  ...avatarProps
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isError, setIsError] = useState(false);

  const imageRef = useRef<HTMLImageElement | null>(null);

  const handleImageLoad = () => {
    setIsLoaded(true);
    if (!src) setIsError(true);
  };

  const handleImageError = () => {
    setIsError(true);
  };

  useIntersection(imageRef, 100, handleImageLoad, isError);

  if (avatar) {
    return (
      <Avatar
        ref={imageRef}
        src={isLoaded && !isError ? src : PlaceholderSrc}
        alt={alt}
        placeholder={PlaceholderSrc}
        onError={handleImageError}
        {...avatarProps}
      >
        {children}
      </Avatar>
    );
  }

  return (
    <img
      ref={imageRef}
      src={isLoaded ? src : PlaceholderSrc}
      alt={alt}
      onError={handleImageError}
      {...(avatarProps as unknown as any)}
    />
  );
};

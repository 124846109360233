import React, { useEffect, useState } from 'react';

import { chunk } from 'lodash';

interface ListMapperHookProps<T extends { id: any }> {
  list: T[];
  renderItem: (item: T, index: number) => React.ReactNode;
  renderCustomItem?: () => React.ReactNode;
  batchSize?: number;
}

const useListMapper = <T extends { id: any }>({
  list,
  renderItem,
  renderCustomItem,
  batchSize = 10,
}: ListMapperHookProps<T>): React.ReactNode[] => {
  const [renderedItems, setRenderedItems] = useState<React.ReactNode[]>([]);
  const [renderedItemIds, setRenderedItemIds] = useState<any[]>([]);

  const processItems = () => {
    const newItems = list.filter((item) => !renderedItemIds.includes(item.id));
    const renderedNewItems = newItems
      .slice(0, batchSize)
      .map((item, index) => {
        const renderedItem = renderItem(item, index);
        if ((index + 1) % 3 === 0 && typeof renderCustomItem === 'function') {
          return [renderedItem, renderCustomItem()];
        }
        return renderedItem;
      })
      .flat();

    setRenderedItems((prev) => [...prev, ...renderedNewItems]);
    setRenderedItemIds((prev) => [...prev, ...newItems.slice(0, batchSize).map((item) => item.id)]);

    chunk(newItems.slice(batchSize), batchSize).forEach((items) =>
      (window?.requestIdleCallback ?? setTimeout)(() => {
        const renderedNewBatchItems = items
          .map((item, index) => {
            const renderedItem = renderItem(item, index);
            if ((index + 1) % 3 === 0 && typeof renderCustomItem === 'function') {
              return [renderedItem, renderCustomItem()];
            }
            return renderedItem;
          })
          .flat();
        setRenderedItems((prev) => [...prev, ...renderedNewBatchItems]);
        setRenderedItemIds((prev) => [...prev, ...items.map((item) => item.id)]);
      })
    );
  };

  useEffect(() => {
    if (renderedItemIds.length === list.length) return;
    processItems();
  }, [list.length]);

  return renderedItems;
};

export default useListMapper;

function useFeatures() {
  return {
    notif: process.env.REACT_APP_ENABLE_NOTIFICATIONS,
    replyToComment: false,
    videosPosts: false,
    discussionEnabled: true,
    managePosts: false,
  };
}

export default useFeatures;

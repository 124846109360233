// RichTextEditorViewer.tsx
import React, { forwardRef, useImperativeHandle, useState } from 'react';

import { Box, colors, darken, lighten, styled } from '@mui/material';
import ReactQuill, { Quill, ReactQuillProps } from 'react-quill';

import { StackRow } from './StackRow';
import { TextLengthInfo, TextLengthInfoProps } from './TextAreaAutoSize';
import { InputErrorAlert } from './TextInput';

import 'react-quill/dist/quill.snow.css';

const Delta = Quill.import('delta');
const Break = Quill.import('blots/break');
const Embed = Quill.import('blots/embed');

interface RichTextEditorViewerProps extends ReactQuillProps {
  initialContent?: string;
  textInfo?: TextLengthInfoProps;
  disableTextInfo?: true;
  errorMsg?: string;
  onChange?: (content: string) => void;
}

const lineBreakMatcher = () => {
  const newDelta = new Delta();
  newDelta.insert({ break: '' });
  return newDelta;
};

const modules: ReactQuillProps['modules'] = {
  toolbar: [
    [{ header: [2, 3, false] }],
    ['bold', 'italic', 'underline'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['code-block', 'blockquote'],
    // [
    // 'link',
    // 'image'
    // ],
    ['clean'],
  ],
  clipboard: {
    matchers: [['BR', lineBreakMatcher]],
    matchVisual: false,
  },
  keyboard: {
    bindings: {
      linebreak: {
        key: 13,
        shiftKey: true,
        handler: function (this: any, range: any) {
          const currentLeaf = this.quill.getLeaf(range.index)[0];
          const nextLeaf = this.quill.getLeaf(range.index + 1)[0];
          this.quill.insertEmbed(range.index, 'break', true, 'user');
          // Insert a second break if:
          // At the end of the editor, OR next leaf has a different parent (<p>)
          if (nextLeaf === null || currentLeaf.parent !== nextLeaf.parent) {
            this.quill.insertEmbed(range.index, 'break', true, 'user');
          }
          // Now that we've inserted a line break, move the cursor forward
          this.quill.setSelection(range.index + 1, (Quill as any).sources.SILENT);
        },
      },
    },
  },
};

const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'list',
  'code-block',
  'blockquote',
  // 'image',
];

export const RichTextEditorViewer = forwardRef<any, RichTextEditorViewerProps>((props, ref) => {
  const {
    initialContent = '',
    textInfo,
    disableTextInfo,
    errorMsg,
    readOnly,
    ...quillProps
  } = props;
  const [content, setContent] = useState<string>(initialContent);

  useImperativeHandle(ref, () => ({
    getContent: () => content,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    focus: () => {},
  }));

  return (
    <StyledBox ref={ref}>
      {!readOnly ? (
        <>
          <ReactQuill
            {...quillProps}
            value={content}
            onChange={(value) => {
              setContent(value);
              quillProps?.onChange?.(value);
            }}
            placeholder='Enter content...'
            modules={modules}
            formats={formats}
          />
          <StackRow justifyContent={errorMsg ? 'space-between' : 'flex-end'} alignItems='center'>
            {errorMsg && <InputErrorAlert msg={errorMsg} />}
            {!disableTextInfo && textInfo && <TextLengthInfo {...textInfo} />}
          </StackRow>
        </>
      ) : (
        <>
          <PreviewBox dangerouslySetInnerHTML={{ __html: content }} />
        </>
      )}
    </StyledBox>
  );
});

const StyledBox = styled(Box)(({ theme }) => ({
  '.ql-toolbar': {
    borderRadius: '0.8rem 0.8rem 0 0',
  },
  '.ql-container': {
    minHeight: '10em',
    borderRadius: '0 0 0.8rem 0.8rem',

    '.ql-editor': {
      maxHeight: '40em',
      overflowY: 'scroll',
      height: '100%',
      p: {
        fontSize: '1rem',
        margin: '0 0 1.2rem 0',
      },
      pre: {
        fontSize: '0.9rem',
        backgroundColor: colors.blueGrey[50],
        padding: '0.5em 1em',
        borderRadius: '0.4rem',
        margin: '0 0 1rem 0',
        color: '#000',
      },
      a: {
        color: colors.blue[700],
        textDecoration: 'none',
        position: 'relative',
        '&:hover': {
          textDecoration: 'underline',
        },
      },
      h2: {
        fontSize: '1.4rem',
        margin: '1.6rem 0 1rem 0',
      },
      h3: {
        margin: '1.6rem 0 1rem 0',
      },
      blockquote: {
        fontSize: '0.95rem',
        fontStyle: 'italic',
        borderLeft: '4px solid #3498db',
        backgroundColor: lighten(colors.blueGrey[50], 0.6),
        margin: '0 0 20px 0',
        padding: '0.5em 1em',
        '&::after': {
          content: '"\\2014 \\00a0"' /* Add an em dash and some space */,
          fontWeight: 'bold',
          display: 'block',
          marginTop: '10px',
        },
        '& blockquote': {
          borderLeftColor: '#e74c3c',
          paddingLeft: '15px',
        },
      },
      ol: {
        paddingLeft: '1rem',
      },
      ul: {
        paddingLeft: '0.5rem',
        li: {
          paddingLeft: '1.6rem',
          '&::before': {
            content: '"•"',
            marginRight: '0.65rem',
          },
        },
      },
      li: {
        fontSize: '1rem',
      },
    },
  },

  '.ql-tooltip': {
    zIndex: `${theme.zIndex.appBar - 1} !important`,
  },
}));

const PreviewBox = styled(Box)(({ theme }) => ({
  fontSize: '1rem',

  a: {
    color: colors.blue[700],
    textDecoration: 'none',
    position: 'relative',
    '&:hover': {
      textDecoration: 'underline',
    },
    '&[href^="http"]:not([href*="app.frulow.com"])': {
      position: 'relative',
      '&::after': {
        content: '"External link"',
        position: 'absolute',
        top: '-1.8em',
        left: '50%',
        width: '6.5em',
        transform: 'translateX(-50%)',
        padding: '0.2em 0.5em',
        backgroundColor: '#333',
        color: '#fff',
        fontSize: '0.8em',
        borderRadius: '4px',
        opacity: '0',
        transition: 'opacity 0.3s ease-in-out',
      },
      '&:hover::after': {
        opacity: '1',
      },
    },
  },
  p: {
    fontSize: '1rem',
    margin: '0 0 1.2rem 0',
  },
  h2: {
    fontSize: '1.4rem',
    margin: '1.6rem 0 1rem 0',
  },
  h3: {
    margin: '1.6rem 0 1rem 0',
  },
  pre: {
    fontSize: '0.9rem',
    backgroundColor: colors.blueGrey[50],
    padding: '0.5em 1em',
    borderRadius: '0.4rem',
    margin: '0 0 1rem 0',
    overflowX: 'scroll',
  },
  blockquote: {
    fontSize: '0.95rem',
    fontStyle: 'italic',
    borderLeft: '4px solid #3498db',
    backgroundColor: lighten(colors.blueGrey[50], 0.6),
    margin: '0 0 20px 0',
    padding: '0.5em 1em',
    '&::after': {
      content: '"\\2014 \\00a0"' /* Add an em dash and some space */,
      fontWeight: 'bold',
      display: 'block',
      marginTop: '10px',
    },
    '& blockquote': {
      borderLeftColor: '#e74c3c',
      paddingLeft: '15px',
    },
  },
  ol: {
    paddingLeft: '2rem',
  },
  ul: {
    paddingLeft: '1.9rem',
  },
  li: {
    fontSize: '1rem',
  },
}));

import React from 'react';

import { Chip, colors, lighten, styled } from '@mui/material';
import { BsHash } from 'react-icons/bs';

interface Props {
  name: string;
  icon?: React.ReactElement;
}

const StyledChip = styled(Chip)(({ theme }) => ({
  fontSize: '0.8rem',
  border: 'none',
  padding: 0,
  marginLeft: 0,
  color: lighten('#000', 0.4),
  '& .MuiChip-label': {
    paddingLeft: '4px',
    paddingRight: '0px',
  },
  '&:hover': {
    color: colors.blue[500],
    svg: { color: colors.blue[300] },
  },
}));

export function CardTagChip({ name, icon }: Props) {
  return <StyledChip variant='outlined' label={name} icon={icon ?? <BsHash />} />;
}

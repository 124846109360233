import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import { ConfirmBox, PromptConfirmOptions, PromptConfirmProps } from '@components/PromptConfirm';

export const ConfirmContext = createContext<{
  askConfirm: (
    title: PromptConfirmProps['title'],
    message: PromptConfirmProps['description'],
    options?: Partial<PromptConfirmOptions>
  ) => Promise<boolean>;
}>({
  askConfirm: async () => false,
});

export const ConfirmProvider = ({ children }: PropsWithChildren) => {
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState<PromptConfirmProps['title']>('');
  const [message, setMessage] = useState<PromptConfirmProps['description']>('');
  const [options, setOptions] = useState<Partial<PromptConfirmOptions>>({});
  const [resolve, setResolve] = useState<(value: boolean) => void>(() => {
    //anything
  });

  const confirm = useCallback(
    async (
      title: PromptConfirmProps['title'],
      message: PromptConfirmProps['description'],
      options?: Partial<PromptConfirmOptions>
    ) =>
      new Promise<boolean>((res) => {
        setOpen(true);
        setTitle(title);
        setMessage(message);
        setOptions(options ?? {});
        setResolve(() => res);
      }),
    []
  );

  const handleAction = (confirmed: boolean) => {
    setOpen(false);
    resolve(confirmed);
  };

  const values = useMemo(() => {
    return { askConfirm: confirm };
  }, [confirm]);

  return (
    <ConfirmContext.Provider value={values}>
      {children}
      {open && (
        <ConfirmBox title={title} description={message} onAction={handleAction} options={options} />
      )}
    </ConfirmContext.Provider>
  );
};

export const useConfirmBox = () => useContext(ConfirmContext);

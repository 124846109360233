import React, { useState } from 'react';

import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { BsCheck, BsEye, BsEyeSlash, BsRepeat } from 'react-icons/bs';

import { useToast } from '@hooks/useToast';
import { resetPassword, sendPasswordForgotToken } from 'api';

import { StackRow, TextInput } from '..';

interface Fields {
  email: string;
  otp: string;
  newPassword: string;
}

interface Props {
  onClose: () => void;
}

function FrogotPassword({ onClose }: Props) {
  const { promiseToast } = useToast();
  const [showPass, setShowPass] = useState(false);
  const [activeStep, setActiveStep] = useState<number>(1);
  const {
    register,
    formState: { errors, isLoading },
    reset,
    handleSubmit,
  } = useForm<Fields>({ mode: 'all' });
  const [loading, setLoading] = useState(false);

  const getOtp = async (data: string) => {
    // Make API call for Step 1 (send OTP)
    return await promiseToast(
      // Replace the following line with your actual API call
      // e.g., postOtpMutate.mutateAsync(data)
      sendPasswordForgotToken(data),
      {
        error: (e) => e?.message ?? 'Error sending OTP. Please try again. ❌',
        loading: 'Sending OTP... ⌛',
        success: () => {
          setActiveStep(2);
          return 'OTP sent successfully! ✅';
        },
      }
    );
  };

  const saveNewPassword = async (data: Fields) => {
    // Make API call for Step 3 (send password)
    return await promiseToast(
      // Replace the following line with your actual API call
      // e.g., postPasswordMutate.mutateAsync(data)
      resetPassword(data.email, data.newPassword, data.otp),
      {
        error: 'Error saving password. Please try again. ❌',
        loading: 'Saving password... ⌛',
        success: () => {
          reset({});
          return 'Password saved successfully! ✅';
        },
      }
    );
  };

  const handleFormSubmit = async (data: Fields) => {
    try {
      setLoading(true);

      if (activeStep === 1) await getOtp(data.email);
      else if (activeStep === 2) setActiveStep(3);
      else if (activeStep === 3) await saveNewPassword(data);
    } catch (error) {
      // Handle errors
      console.error('Error:', error);
      // Additional error handling can be added here
    } finally {
      setLoading(false);
    }
  };

  const stepDoneProps = {
    disabled: true,
    endAdornment: (
      <IconButton color='success'>
        <BsCheck />
      </IconButton>
    ),
  };

  return (
    <Stack direction='row' alignItems='center' justifyContent='center' sx={{ height: '100dvh' }}>
      <Box
        sx={{
          p: 4,
          width: '30rem',
          minHeight: '10rem',
          bgcolor: (theme) => theme.palette.common.white,
          borderRadius: '1rem',
          boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
          position: 'relative',
        }}
        component='form'
        onSubmit={handleSubmit(handleFormSubmit)}
      >
        <Typography sx={{ fontWeight: 500, fontSize: '1.2rem' }}>Password Recovery</Typography>

        <Box>
          {activeStep >= 1 && (
            <Box sx={{ mt: 2.6 }}>
              <Typography sx={{ fontSize: '1rem', mb: 1, color: 'GrayText', fontWeight: 500 }}>
                Email
              </Typography>

              <TextInput
                placeholder='Email'
                type='email'
                {...(activeStep <= 1
                  ? {
                      inputProps: {
                        ...register('email', {
                          required: { value: true, message: 'Email is required' },
                          validate: (val) => {
                            const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                            return emailRegex.test(val) ? true : 'Email must be valid';
                          },
                        }),
                      },
                      errorMsg: errors.email?.message,
                    }
                  : stepDoneProps)}
              />
            </Box>
          )}

          {activeStep >= 2 && (
            <Box sx={{ mt: 2 }}>
              <Typography sx={{ fontSize: '1rem', mb: 0, color: 'GrayText', fontWeight: 500 }}>
                One Time Password
              </Typography>
              <Typography variant='body2' sx={{ fontSize: '0.85rem', color: 'GrayText', mb: 1.2 }}>
                Check your registered email for the OTP. If not received, ensure your email is
                registered with us.
              </Typography>

              <TextInput
                placeholder='OTP'
                inputProps={{
                  ...register('otp', {
                    required: { value: true, message: 'OTP is required' },
                    minLength: {
                      value: 4,
                      message: 'OTP must be 4 characters long',
                    },
                    maxLength: {
                      value: 4,
                      message: 'OTP must be 4 characters long',
                    },
                  }),
                }}
                errorMsg={errors.otp?.message}
              />
            </Box>
          )}

          {activeStep === 3 && (
            <Box sx={{ mt: 2, mb: 3 }}>
              <Typography sx={{ fontSize: '1rem', mb: 1, color: 'GrayText', fontWeight: 500 }}>
                New Password
              </Typography>
              <TextInput
                placeholder='New Password'
                type={showPass ? 'text' : 'password'}
                endAdornment={
                  <Tooltip title={showPass ? 'Hide' : 'Show'}>
                    <IconButton size='small' onClick={() => setShowPass((prev) => !prev)}>
                      {showPass ? <BsEyeSlash /> : <BsEye />}
                    </IconButton>
                  </Tooltip>
                }
                inputProps={{
                  ...register('newPassword', {
                    required: { value: true, message: 'Password is required' },
                    minLength: { value: 8, message: 'Password must be at least 8 characters long' },
                    maxLength: { value: 20, message: 'Password must not exceed 20 characters' },
                    validate: (val: string) => {
                      const strongPassRegex =
                        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^*-]).{8,}$/;

                      if (!strongPassRegex.test(val)) {
                        return 'Password must contain at least one lowercase letter, one uppercase letter, one digit, and one special character (#?!@$%^*-). Minimum length is 8 characters.';
                      }

                      return true;
                    },
                  }),
                }}
                errorMsg={errors.newPassword?.message}
              />
            </Box>
          )}
        </Box>

        <StackRow justifyContent='space-between' sx={{ mt: 2 }} spacing={3}>
          <Button
            fullWidth
            variant='text'
            startIcon={<BsRepeat />}
            onClick={onClose}
            disabled={isLoading || loading}
          >
            Login
          </Button>

          <Button fullWidth type='submit' disabled={isLoading || loading}>
            {isLoading || loading ? (
              <CircularProgress
                sx={{ color: 'common.white', mr: 1, maxWidth: '1.6rem', maxHeight: '1.6rem' }}
              />
            ) : (
              'Submit'
            )}
          </Button>
        </StackRow>
      </Box>
    </Stack>
  );
}

export default FrogotPassword;

import { colors } from '@mui/material';
import toast, { DefaultToastOptions, ToastOptions } from 'react-hot-toast';
import { BsInfoCircleFill } from 'react-icons/bs';

export const defaultToasterOptions: DefaultToastOptions = {
  duration: 4000,
  style: {
    fontSize: '1rem',
  },
  position: 'top-right',
};

export const useToast = () => {
  const successToast = (message: string, options?: ToastOptions) => {
    toast.success(message, options);
  };

  const errorToast = (message: string, options?: ToastOptions) => {
    toast.error(message, options);
  };

  const infoToast = (message: string, options?: ToastOptions) => {
    toast(message, { ...options, icon: <BsInfoCircleFill color={colors.blue[500]} size={20} /> });
  };

  const promiseToast = toast.promise;

  const customToast = toast.custom;

  return {
    successToast,
    errorToast,
    infoToast,
    promiseToast,
    customToast,
    dismissToast: toast.dismiss,
  };
};

import * as React from 'react';

import { ThemeProvider, Typography, colors, styled } from '@mui/material';
import Button, { ButtonProps } from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText, { DialogContentTextProps } from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import lightTheme from '@libs/theme';

export interface PromptConfirmOptions {
  acceptButtonProps: ButtonProps;
  rejectButtonProps: ButtonProps;
  descriptionProps: DialogContentTextProps;
}
export interface PromptConfirmProps {
  title: React.ReactElement | string;
  description: React.ReactElement | string;
  options: Partial<PromptConfirmOptions>;
  onAction: (proceed: boolean) => void;
}

export function ConfirmBox({
  title,
  description,
  onAction,
  options,
}: Readonly<PromptConfirmProps>) {
  const _handleCancel = () => onAction(false);
  const _handleOkay = () => onAction(true);

  return (
    <ThemeProvider theme={lightTheme}>
      <Dialog
        open
        onClose={_handleCancel}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        PaperProps={{ sx: { borderRadius: 3, pb: 1, maxWidth: '20em' } }}
      >
        <DialogTitle id='alert-dialog-title'>
          <Typography variant='h5' sx={{ fontWeight: 700, fontSize: '1.2rem' }}>
            {title}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id='alert-dialog-description'
            sx={{ fontSize: '1rem' }}
            {...options?.descriptionProps}
          >
            {description}
          </DialogContentText>
        </DialogContent>
        <StyledDialogAction sx={{ px: 3 }}>
          <Button
            variant='outlined'
            color='inherit'
            sx={{ bgcolor: colors.blueGrey[100], border: 'none' }}
            {...options?.acceptButtonProps}
            onClick={_handleOkay}
          >
            Yes
          </Button>
          <Button
            variant='contained'
            autoFocus
            color='primary'
            {...options?.rejectButtonProps}
            onClick={_handleCancel}
          >
            No
          </Button>
        </StyledDialogAction>
      </Dialog>
    </ThemeProvider>
  );
}

const StyledDialogAction = styled(DialogActions)(({ theme }) => ({
  '& .MuiButton-root': {
    padding: '0.3em 2em',
    fontSize: '1rem',
    width: 'max-content',
    borderRadius: '0.6em',
  },
}));

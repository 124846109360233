import {
  GetAllFollowedTagsResponse,
  GetUserPersonalizedTagsSuggestions,
  SimilarTagsByTagId,
  TagByIdResponse,
  TrendingTagsResponse,
} from './types';
import API from '../_axios';

export type SearchTagResponse = Pick<Tag, 'id' | 'name'>;

export const searchTag = async (keyword: string) => {
  const result = await API.get<SearchTagResponse[]>({ url: '/tag/search', params: { keyword } });
  return result.data;
};

export const searchProgLangs = async (keyword: string) => {
  const result = await API.get<SearchTagResponse[]>({
    url: '/tag/search/lang',
    params: { keyword },
  });
  return result.data;
};

export const getTrendingTags = async () => {
  const result = await API.get<TrendingTagsResponse>({ url: '/tag/trending' });
  return result.data;
};

export const getTagsById = async (id: number, userId?: number) => {
  const result = await API.get<TagByIdResponse>({ url: `/tag/${id}`, params: { userId } });
  return result.data;
};

export const getSimilarByTagId = async (id: number) => {
  const result = await API.get<SimilarTagsByTagId>({ url: `/tag/similar/${id}` });
  return result.data;
};

export const getAllFollowedTags = async () => {
  const result = await API.get<GetAllFollowedTagsResponse[]>({ url: `/tag` });
  return result.data;
};

export const getPersonalizeUserTagsSuggestions = async () => {
  const result = await API.get<GetUserPersonalizedTagsSuggestions[]>({
    url: `/tag/random/personalized`,
  });
  return result.data;
};

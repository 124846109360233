import { useCallback, useEffect, useState } from 'react';

import { useQuery, useQueryClient } from '@tanstack/react-query';
import { isNil, omit, pick } from 'lodash';
import { UseFormReturn, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { AppRoutes } from '@constants/appRoutes';
import { useRouter } from '@hooks/useRouter';
import { useToast } from '@hooks/useToast';
import { queryKeys } from 'api';
import { getUsersOwnPost, updatePostById } from 'api/post';
import { GetUserOwnPostResponse, PostPayloadComplete } from 'api/post/types';
import { SearchTagResponse } from 'api/tag';

type TagTypeData = { id: number; name: string };

export type HandleDataUpdateFn = (
  key: keyof GetUserOwnPostResponse,
  value: GetUserOwnPostResponse[keyof GetUserOwnPostResponse]
) => void;

export type Data = GetUserOwnPostResponse & {
  tags: TagTypeData[];
  progLangs: TagTypeData[];
  makers?: Maker[];
};

export type Fields = {
  name: string;
  tagline: string;
  description: string;
  tags: (SearchTagResponse & { isNew?: boolean })[];
  progLangs: (SearchTagResponse & { isNew?: boolean })[];
  youtubeUrl: GetUserOwnPostResponse['youtubeUrl'];
};

export type Form = UseFormReturn<PostPayloadComplete, any, undefined>;

export type HandleDataSaveFn = (data: Partial<PostPayloadComplete>) => Promise<void>;

function removeNullishValues(obj: Record<string, any>) {
  const result: Record<string, any> = {};
  for (const key in obj) {
    if (!isNil(obj[key]) && (typeof obj[key] !== 'string' || obj[key].trim() !== '')) {
      result[key] = obj[key];
    }
  }
  return result;
}

function useExistingDraft() {
  const queryClient = useQueryClient();
  const { promiseToast } = useToast();
  const params = useParams() as { id?: string };
  const { searchParams, navigateTo } = useRouter();
  const draftId = searchParams.get('draft_id') || params?.id;

  const [blockStepChange, setBlockStepChange] = useState(false);
  const [activeStep, setActiveStep] = useState(1);
  const [data, setData] = useState<Data | undefined>(undefined);
  const [isUpdating, setIsUpdating] = useState(false);

  const {
    data: res,
    isFetching,
    refetch,
    dataUpdatedAt,
    isSuccess,
  } = useQuery(
    [queryKeys.getOwnPostById, draftId],
    () => getUsersOwnPost({ id: +draftId! }) as any as Promise<GetUserOwnPostResponse>,
    {
      select: (res) => {
        return {
          ...res,
          progLangs: res.proglangs,
          tags: res.tags,
          makers: res?.makers?.filter((item) => item.id && item.label) ?? [],
        };
      },
      enabled: !!draftId,
    }
  );

  const handleStepChange = useCallback(() => {
    let step = 1;
    if (data) {
      if (data.id) step = 2;
      if (data.galleryImages && data.thumbnailUrl) step = 3;
    }

    setActiveStep(step);
  }, [data]);

  useEffect(() => {
    if (blockStepChange) return;
    if (!data?.id) return setActiveStep(1);
    handleStepChange();
    setBlockStepChange(true);
  }, [blockStepChange, data, handleStepChange]);

  const handleDataUpdate: HandleDataUpdateFn = useCallback(
    (key, value) => {
      if (data) setData({ ...data, [key]: value });
    },
    [data]
  );

  useEffect(() => {
    setData(res as any);
  }, [res]);

  const form = useForm<PostPayloadComplete>({
    mode: 'all',
    defaultValues: {
      ...omit(data, 'youtubeUrl', 'postedByMaker', 'isOpenSource'),
      youtubeUrl: data?.youtubeUrl ?? undefined,
      postedByMaker: data?.postedByMaker ?? undefined,
      isOpenSource: data?.isOpenSource ?? undefined,
    },
  });

  const handleDataSave: HandleDataSaveFn = useCallback(
    async (data) => {
      if (!draftId) return;
      try {
        setIsUpdating(true);
        const result = await promiseToast(
          updatePostById(
            +draftId,
            removeNullishValues(
              pick(
                data,
                'description',
                'isOpenSource',
                'name',
                'postedByMaker',
                'pricingModel',
                'progLangs',
                'tagline',
                'tags',
                'usefulLinks',
                'youtubeUrl',
                'makers'
              )
            )
          ),
          {
            error: `Oops! Something went wrong while publishing changes. 😟`,
            loading: `Saving changes... Please wait. ⌛`,
            success: (data) => {
              if (data.isPublished && activeStep === 3) {
                return `Changes published successfully! Your library is now public. 🎉`;
              }
              return `Changes saved successfully!`;
            },
          }
        );

        setData((prev) => ({ ...prev, ...data } as any));

        // Must not be always published
        if (activeStep === 3 && result.isPublished && !res?.isPublished)
          navigateTo(AppRoutes.dashboard);
      } catch (error) {
        console.log(error);
      } finally {
        setIsUpdating(false);
      }
    },
    [activeStep, draftId, navigateTo, promiseToast, res?.isPublished]
  );

  const updateQueryResDirectly = useCallback(
    (key: keyof GetUserOwnPostResponse, value: unknown) => {
      queryClient.setQueryData([queryKeys.getOwnPostById, draftId], { ...res, [key]: value });
    },
    [draftId, queryClient, res]
  );

  return {
    form,
    activeStep,
    isFetching,
    draftData: data,
    isEnabled: !!draftId,
    draftRefetch: refetch,
    dataUpdate: handleDataUpdate,
    dataSaveToDb: handleDataSave,
    changeStep: setActiveStep,
    isUpdating,
    dataUpdatedAt,
    isSuccess,
    updateQueryResDirectly,
  };
}

export default useExistingDraft;

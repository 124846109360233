import React from 'react';

import { useMutation, useQuery } from '@tanstack/react-query';
import { Link } from 'react-router-dom';

import { AppRoutes } from '@constants/appRoutes';
import { followUserById, queryKeys, verifyIfFollowed } from 'api';

import usePermission from './usePermission';
import { useToast } from './useToast';

type Props = { author: Author; enabled?: boolean };

function useAuthor({ author, enabled = true }: Props) {
  const { promiseToast } = useToast();
  const { isUserValid } = usePermission();

  const isFollowed = useQuery(
    [queryKeys.getFollowedStatusById, author?.id],
    () => verifyIfFollowed(author?.id),
    { enabled: !!author?.id && !!isUserValid() && enabled }
  );

  const followUserMutate = useMutation(followUserById);

  const handleFollowUseById = async () => {
    if (!author) return;

    const isFollowing = isFollowed.data?.followed;
    const toastMessages = {
      error: 'An error occurred while following the user.',
      loading: `${isFollowing ? 'Unfollowing' : 'Following'} user...`,
      success: `Successfully ${isFollowing ? 'unfollowed' : 'followed'} user.`,
    };

    await promiseToast(followUserMutate.mutateAsync(author.id), toastMessages);
    isFollowed.refetch();
  };

  const withLink = (el: React.ReactNode) => (
    <Link to={AppRoutes.profile({ id: author?.userName as string })}>{el}</Link>
  );

  return { withLink, handleFollowUseById, isFollowed };
}

export default useAuthor;

import React from 'react';

import { Skeleton, Stack } from '@mui/material';

function NotificationItemSkeleton() {
  return (
    <Stack spacing={1} p={2}>
      <Skeleton variant='rectangular' width='100%' height='2.5em' />
      <Skeleton variant='rectangular' width='100%' height='2.5em' />
      <Skeleton variant='rectangular' width='100%' height='2.5em' />

      <Skeleton variant='rectangular' width='100%' height='2.5em' />
      <Skeleton variant='rectangular' width='100%' height='2.5em' />
      <Skeleton variant='rectangular' width='100%' height='2.5em' />
      <Skeleton variant='rectangular' width='100%' height='2.5em' />
    </Stack>
  );
}

export default NotificationItemSkeleton;

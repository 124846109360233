// withHelmet.tsx

import React from 'react';

import { Helmet } from 'react-helmet-async';
import { JsxFragment } from 'typescript';

// Define the type for the props that the wrapped component should receive
interface WithHelmetProps {
  title: string;
  description?: string;
  extras?: JsxFragment;
  keywords?: string;
}

export const Headers = (props: WithHelmetProps) => {
  return (
    <Helmet>
      {/* Add your helmet tags here */}
      <title>{props.title ?? 'Frulow.com: Coding Libraries Hub!'}</title>
      <meta
        name='description'
        content={
          props.description ??
          'Frulow: Your go-to platform for discovering diverse libraries across different languages. Simplify your search and find the most relevant libraries all in one place.'
        }
      />
      <meta
        name='keywords'
        content={
          props.keywords ??
          'Frulow, library collections, programming libraries, code repositories, developer resources, coding tools, programming languages'
        }
      />
      <meta
        name='og:description'
        content={
          props.description ??
          'Frulow: Unlock a world of possibilities by exploring a centralized hub for libraries across various languages. Simplify your search and elevate your coding experience today!'
        }
      />
      {/* Add any other meta tags, link tags, etc. */}
    </Helmet>
  );
};

export const baseURL = process.env.REACT_APP_AXIOS_BASE_URL || '';

export const containerMaxWidth = 'xl';

export const fullHeightWithNav =
  window.innerWidth <= 768 ? 'calc(100dvh - 1.8em - 3.4em)' : 'calc(100dvh - 56.8px)';

export const pricingModelMappedNames = {
  FREEMIUM: 'Freemium',
  FREE: 'Free',
  PAID: 'Paid',
};

export enum LikeDislikeType {
  LIKE = 'LIKE',
  DISLIKE = 'DISLIKE',
  NONE = 'NONE',
}

export enum ObjectTypes {
  Library = 'Library',
  USER = 'USER',
  TAG = 'TAG',
  COMMENT = 'COMMENT',
}

// Define an object for toast durations in milliseconds
export const TOAST_DURATION = {
  LONG: 6000, // 6 seconds
  SHORT: 2000, // 2 seconds
  MEDIUM: 4000, // 4 seconds
  VERY_LONG: 8000, // 8 seconds
  VERY_SHORT: 1500, // 1.5 seconds
};

export const DETAILS_TABS_DRAWER_INDEXS = {
  INFO: 0,
  COMMENT: 1,
};

export enum NotificationType {
  POST_LIKE = 'POST_LIKE',
  NEW_POST_COMMENT = 'NEW_POST_COMMENT',
  COMMENT_REPLY = 'COMMENT_REPLY',
  PROFILE_FOLLOW = 'PROFILE_FOLLOW',
  COLLECTION_FOLLOW = 'COLLECTION_FOLLOW',
  COLLECTION_LIKE = 'COLLECTION_LIKE',
  NEW_POST_PUBLISHED = 'NEW_POST_PUBLISHED',
  FOLLOWED_PERSON_PUBLISHED = 'FOLLOWED_PERSON_PUBLISHED',
}

export const COLLECTION_PRIVACY = {
  private: 'private',
  public: 'public',
};

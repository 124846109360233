import moment from 'moment';

type Props =
  | {
      date?: Date | string;
    }
  | Date
  | string;

export const getShortestDuration = (data: Props): string => {
  const date = typeof data !== 'object' ? data : (data as Record<string, any>).date;
  const currentDate = moment();
  const inputDate = moment(date);

  const duration = moment.duration(currentDate.diff(inputDate));

  const seconds = duration.asSeconds();
  const minutes = duration.asMinutes();
  const hours = duration.asHours();
  const days = duration.asDays();
  const months = duration.asMonths();
  const years = duration.asYears();

  if (Math.abs(seconds) < 60) {
    return `${Math.round(seconds)}s`;
  } else if (Math.abs(minutes) < 60) {
    return `${Math.round(minutes)}m`;
  } else if (Math.abs(hours) < 24) {
    return `${Math.round(hours)}h`;
  } else if (Math.abs(days) < 7) {
    return `${Math.round(days)}d`;
  } else if (Math.abs(months) < 1) {
    return `${Math.round(days / 7)}w`;
  } else if (Math.abs(years) < 1) {
    return `${Math.round(months)} mo`;
  } else {
    return `${Math.round(years)}y`;
  }
};

import { lazy } from 'react';

import { RouteObject, redirect } from 'react-router-dom';

import { AppRoutes } from '@constants/appRoutes';
import { ExistingDraftProvider } from '@hoc/ExistingDraft.context';
import { HomePage } from '@pages/Home';
import Layout from '@pages/Layout';
import AppPrivateRoute from '@router/AppPrivateRoute';
import { LazyRoute } from '@router/LazyRoute';

const DashboardPage = lazy(() =>
  import('@pages/Dashboard').then((module) => ({ default: module.DashboardPage }))
);
const NewPostPage = lazy(() =>
  import('@pages/NewPost').then((module) => ({ default: module.NewPostPage }))
);
const ProfilePage = lazy(() =>
  import('@pages/Profile').then((module) => ({ default: module.ProfilePage }))
);
const PostByTagPage = lazy(() =>
  import('@pages/PostsByTag').then((module) => ({ default: module.PostsByTag }))
);
const DetailsPage = lazy(() =>
  import('@pages/Details').then((module) => ({ default: module.DetailsPage }))
);
const ProPublishPage = lazy(() =>
  import('@pages/ProPublish').then((module) => ({ default: module.ProPublishPage }))
);

// const EditPostPage = lazy(() =>
//   import('@pages/EditPost').then((module) => ({ default: module.EditPostPage }))
// );
const PaymentPage = lazy(() =>
  import('@pages/Payment').then((module) => ({ default: module.PaymentPage }))
);

export default [
  {
    element: <Layout />,
    children: [
      { path: AppRoutes.home, element: <HomePage /> },
      {
        path: AppRoutes.postDetail({ id: ':id' }),
        loader: ({ params }) => {
          if (!params?.id || Number.isNaN(+params?.id)) return redirect(AppRoutes.home);
          return true;
        },
        element: (
          <LazyRoute>
            <DetailsPage />
          </LazyRoute>
        ),
      },
      {
        path: AppRoutes.proPublish({ id: ':id', type: ':type' as any }),
        loader: ({ params }) => {
          if (!params?.id || Number.isNaN(+params?.id)) return redirect(AppRoutes.home);
          if (!params?.type || !['fail', 'success'].includes(params.type))
            return redirect(AppRoutes.home);
          return true;
        },
        element: (
          <LazyRoute>
            <ProPublishPage />
          </LazyRoute>
        ),
      },
      {
        path: AppRoutes.postsByTag({ id: ':id', tagName: ':tagName' }),
        element: (
          <LazyRoute>
            <PostByTagPage />
          </LazyRoute>
        ),
        loader: ({ params }) => {
          if (!params.id || !params.tagName) return redirect(AppRoutes.home);
          if (Number.isNaN(+params?.id)) return redirect(AppRoutes.home);
          return true;
        },
      },
      {
        path: AppRoutes.profile({ id: ':id' }),
        loader: ({ params }) => {
          if (
            !params?.id ||
            ['null', 'undefined'].includes(params?.id) ||
            !Number.isNaN(+params?.id)
          ) {
            return redirect('/');
          }

          return true;
        },
        element: (
          <LazyRoute>
            <ProfilePage />
          </LazyRoute>
        ),
      },
      {
        element: <AppPrivateRoute />,
        children: [
          {
            path: AppRoutes.newPost,
            element: (
              <LazyRoute>
                <ExistingDraftProvider>
                  <NewPostPage />
                </ExistingDraftProvider>
              </LazyRoute>
            ),
          },
          {
            path: AppRoutes.payment({ id: ':id' }),
            loader: ({ params }) => {
              if (
                !params?.id ||
                ['null', 'undefined'].includes(params?.id) ||
                Number.isNaN(+params?.id)
              ) {
                return redirect('/');
              }

              return true;
            },
            element: (
              <LazyRoute>
                <PaymentPage />
              </LazyRoute>
            ),
          },
          // {
          //   path: AppRoutes.managePost({ id: ':id' }),
          //   element: (
          //     <LazyRoute>
          //       <ExistingDraftProvider>
          //         <EditPostPage />
          //       </ExistingDraftProvider>
          //     </LazyRoute>
          //   ),
          // },
          {
            path: AppRoutes.dashboard,
            element: (
              <LazyRoute>
                <DashboardPage />
              </LazyRoute>
            ),
          },
        ],
      },
    ],
  },
] as RouteObject[];

import { isUndefined } from 'lodash';

import {
  FeaturedPostsResponse,
  GetAllUsersPostsWithFilter,
  GetByKeywordResponse,
  GetPostByIdResponse,
  GetUserOwnPostResponse,
  PostCreatePayload,
  PostPayloadComplete,
  PostStatisticsResponse,
  TrendingPostsResponse,
} from './types';
import API from '../_axios';

function getUrl(endpoint: string) {
  return `/posts${endpoint}`;
}

export const createNewPost = async (data: PostCreatePayload) => {
  const result = await API.put<Library>({ url: '/posts', data });
  return result.data;
};

export const getUsersOwnPost = async (params: GetAllUsersPostsWithFilter) => {
  const result = await API.get<
    typeof params.id extends number ? GetUserOwnPostResponse : GetUserOwnPostResponse[]
  >({ url: '/posts/users', params: params });
  return result.data;
};

export const deleteOwnPostById = async (id: number) => {
  const result = await API.Delete<{ id: number }>({ url: `/posts/${id}` });
  return result.data;
};

export const getPostById = async (id: number) => {
  const result = await API.get<GetPostByIdResponse>({ url: `/posts/${id}` });
  return result.data;
};

export const deleteGalleryImageByKey = async (id: number, key: string) => {
  const result = await API.Delete<{ delete: boolean; galleryImages: GalleryImages }>({
    url: `/posts/${id}/gallery`,
    data: { key },
  });
  return result.data;
};

export const updatePostById = async (id: number, data: Partial<PostPayloadComplete>) => {
  const payload = data as any;
  const result = await API.post<GetUserOwnPostResponse>({
    url: `/posts/${id}`,
    data: {
      ...payload,
      ...(!isUndefined(payload.isOpenSource) && { isOpenSource: payload.isOpenSource === 'yes' }),
      ...(!isUndefined(payload.postedByMaker) && {
        postedByMaker: payload.postedByMaker === 'yes',
      }),
    },
  });

  return result.data;
};

export const getPostStats = async (id: number[]) => {
  const result = await API.post<PostStatisticsResponse>({ url: `/posts/statistics`, data: { id } });
  return result.data;
};

export const getTrendingPosts = async (page: number) => {
  const result = await API.get<TrendingPostsResponse>({ url: `/posts/trending`, params: { page } });
  return result.data;
};

export const getAllByUserId = async (userId: string, page: number) => {
  const result = await API.get<TrendingPostsResponse>({
    url: `/posts/users/${userId}`,
    params: { page },
  });
  return result.data;
};

export const removePostMakerByPostId = async (postId: number, makerId: number) => {
  const result = await API.post<{ deleted: true }>({
    url: `/posts/${postId}/maker/remove`,
    data: { makerId },
  });
  return result.data;
};

export const addPostMakerByPostId = async (
  postId: number,
  data: Pick<Maker, 'label' | 'value'>
) => {
  const result = await API.post<Maker>({
    url: `/posts/${postId}/maker/add`,
    data: data,
  });
  return result.data;
};

export const getPostsByTagId = async (id: number, page: number) => {
  const result = await API.get<TrendingPostsResponse>({
    url: `/posts/tag`,
    params: { tagId: id, page },
  });
  return result.data;
  // return {
  //   nextPage: (result?.data?.length ?? 0) >= 20 ? page + 1 : undefined,
  //   pageData: result.data,
  // };
};

export const getIsPostTrending = async (postId: number) => {
  const result = await API.get<number | false>({
    url: `/posts/${postId}/is-trending`,
  });
  return result.data;
};

export const getPostByKeyword = async (keyword: string, page: number) => {
  const result = await API.get<GetByKeywordResponse>({
    url: `/posts/keyword`,
    params: { keyword, page },
  });
  return result.data;
};

export const getPostsByProgLang = async (page: number, progLangId?: number) => {
  const result = await API.get<TrendingPostsResponse>({
    url: getUrl('/progLang'),
    params: { page, progLangId },
  });
  return result.data;
};

export const getRecentlyPublishedPaginated = async (page: number) => {
  const result = await API.get<TrendingPostsResponse>({
    url: getUrl('/recent-published/paginated'),
    params: { page },
  });
  return result.data;
};

export const publishProLibById = async ({
  id,
  isIndian,
  date,
}: {
  id: number;
  isIndian: boolean;
  date: string;
}) => {
  const result = await API.post<{ sessionId: string }>({
    url: `/posts/${id}/publish/pro`,
    data: {
      isIndian,
      date,
    },
  });

  return result.data;
};

export const publishWithoutEnhancements = async ({ id }: { id: number }) => {
  const result = await API.post<{ success: boolean }>({
    url: `/posts/${id}/publish`,
  });

  return result.data;
};

export const getPostToFeature = async (id: number) => {
  const result = await API.get<Pick<Library, 'tagline' | 'name' | 'id' | 'thumbnailUrl'>>({
    url: `/posts/${id}/publish/pro/info`,
  });
  return result.data;
};

export const getFeaturedPosts = async () => {
  const result = await API.get<FeaturedPostsResponse>({
    url: `/posts/featured`,
  });
  return result.data;
};

export const getCapturedSlots = async () => {
  const result = await API.get<{ startDate: string; endDate: string }[]>({
    url: `/posts/featured/slots/captured`,
  });
  return result.data;
};

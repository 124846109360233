import { Avatar, Box, Button, Stack, Tooltip, Typography, lighten } from '@mui/material';
import { useToggle } from '@uidotdev/usehooks';
import { BsPlus } from 'react-icons/bs';
import { Link } from 'react-router-dom';

import { ButtonWithLoader } from '@components/ButtonWithLoader';
import { TextTruncateWithoutExpand } from '@components/TexTruncate';
import { AppRoutes } from '@constants/appRoutes';
import useAuthor from '@hooks/useAuthor';
import usePermission from '@hooks/usePermission';
import useResponsive from '@hooks/useResponsive';
import { preventDefaultWrapper } from '@utility/button';
import { TrendingPostsResponse } from 'api/post/types';

interface Props {
  author: TrendingPostsResponse[number]['author'];
}
function Author({ author }: Readonly<Props>) {
  const [val, onToggle] = useToggle(false);
  const { isDownLg } = useResponsive();
  const { handleFollowUseById, withLink, isFollowed } = useAuthor({ author, enabled: val });
  const { isOwner, secureActionWrapper } = usePermission();

  if (!author) return null;

  return (
    <Tooltip
      onOpen={() => onToggle()}
      title={
        <Stack alignItems='center' justifyContent='flex-start' direction='row'>
          {withLink(
            <Avatar sx={{ width: '2.6rem', height: '2.6rem' }} src={author.profilePicUrl as string}>
              {author.fullName.slice(0, 1)}
            </Avatar>
          )}
          {withLink(
            <Box sx={{ ml: 1 }}>
              <Typography sx={{ fontSize: '0.9rem', fontWeight: 700 }}>
                {author.fullName}
              </Typography>
              <Typography sx={{ fontSize: '0.7rem' }}>@{author.userName}</Typography>
            </Box>
          )}
          {!isOwner(author.id) && (
            <ButtonWithLoader
              isLoading={isFollowed.isFetching}
              startIcon={<BsPlus />}
              color='inherit'
              sx={{
                p: 0.5,
                px: 1,
                fontSize: '0.75rem',
                ml: 1,
                color: '#000',
                borderRadius: 1.6,
              }}
              disabled={isFollowed.isFetching || isFollowed.isError}
              onClick={preventDefaultWrapper(secureActionWrapper(handleFollowUseById))}
            >
              {isFollowed.data?.followed ? 'Following' : 'Follow'}
            </ButtonWithLoader>
          )}
        </Stack>
      }
    >
      <Link to={AppRoutes.profile({ id: author.userName as string })}>
        <Button
          className='author-btn'
          variant='text'
          color='inherit'
          startIcon={
            <Avatar
              sx={{ width: { xs: '1.4rem', lg: '1rem' }, height: { xs: '1.4rem', lg: '1rem' } }}
              src={author.profilePicUrl as string}
            >
              {author.fullName.slice(0, 1)}
            </Avatar>
          }
          sx={{
            p: 0,
            fontSize: { xs: '0.7rem', lg: '0.75rem' },
            color: lighten('#000', 0.5),
            mt: -0.5,
          }}
        >
          {!isDownLg && (
            <TextTruncateWithoutExpand
              text={isOwner(author.id) ? 'You' : author.fullName}
              length={16}
            />
          )}
        </Button>
      </Link>
    </Tooltip>
  );
}

export default Author;

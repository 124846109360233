import React from 'react';

import {
  Divider,
  IconButton,
  Menu,
  MenuProps as MuiMenuProps,
  Stack,
  Tooltip,
  Typography,
  alpha,
  styled,
} from '@mui/material';
import { BsEnvelopeCheck } from 'react-icons/bs';

import useResponsive from '@hooks/useResponsive';
import { rv } from '@utility/styles';

import NotificationItems from './NotificationItems';
import NotificationItemSkeleton from './NotificationItemSkeleton';
import { NotifClickFunction } from './useNotificationPanel';

export interface MenuProps {
  anchorEl: HTMLElement | null;
  isMenuOpen: boolean;
  handleMenuClose: () => void;
  onNotifClick: NotifClickFunction;
  data: NotificationData[];
  isLoading: boolean;
  onReadAll: () => void;
}

const menuId = 'notifications-panel';

export function NotificationPanel({
  anchorEl,
  handleMenuClose,
  isMenuOpen,
  onNotifClick,
  data,
  isLoading,
  onReadAll,
}: Readonly<MenuProps>) {
  const { isDownLg } = useResponsive();
  return (
    <StyledMenu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      slotProps={{
        paper: { sx: { left: isDownLg ? rv('0px !important', undefined) : undefined } },
      }}
    >
      <Stack>
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
          sx={{ px: 2, py: 1, position: 'sticky', top: 0, bgcolor: 'white', zIndex: 1 }}
        >
          <Typography variant='body1' sx={{ fontWeight: 700, fontSize: '1rem' }}>
            Notifications
          </Typography>
          <Tooltip title='Read all'>
            <IconButton onClick={onReadAll}>
              <BsEnvelopeCheck />
            </IconButton>
          </Tooltip>
        </Stack>
        <Divider />

        <Stack>
          {isLoading ? (
            <NotificationItemSkeleton />
          ) : (
            <NotificationItems data={data} onNotifClick={onNotifClick} />
          )}
        </Stack>
      </Stack>
    </StyledMenu>
  );
}

const StyledMenu = styled((props: MuiMenuProps) => <Menu elevation={0} {...props} />)(
  ({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 400,
      maxWidth: 400,
      maxHeight: 600,
      color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',

      [theme.breakpoints.down('lg')]: {
        maxWidth: '80vw',
        minWidth: '100%',
      },
      '& .MuiMenu-list': {
        padding: '4px 0',
        background: 'white',
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          minWidth: '2em',
          fontSize: '1.4rem',
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        '&:active': {
          backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
        },
      },
    },
  })
);

import { Button, Stack, Tooltip, colors, lighten, styled } from '@mui/material';
import {
  BsHandThumbsDown,
  BsHandThumbsDownFill,
  BsHandThumbsUp,
  BsHandThumbsUpFill,
} from 'react-icons/bs';

import { LikeDislikeType } from '@constants/index';
import { numberToWords } from '@utility/numberToWords';

interface LikeDislikeButtonProps {
  isLoading: boolean;
  alreadySelectedType: LikeDislikeType;
  likes: number;
  dislikes: number;
  onLikeClick: () => void;
  onDislikeClick: () => void;
}

const ButtonStyled = styled(Button)(({ theme }) => ({
  fontSize: '0.9rem',
  [theme.breakpoints.down('lg')]: {
    padding: 0,
    fontSize: '0.8rem',
    svg: {
      fontSize: '0.6rem',
    },
  },
}));

export const LikeDislikeButton = ({
  isLoading,
  alreadySelectedType,
  likes,
  dislikes,
  onLikeClick,
  onDislikeClick,
}: LikeDislikeButtonProps) => {
  const likeSize = 16;
  const dislikeSize = 16;
  return (
    <Stack
      direction='row'
      sx={{
        minWidth: { xs: '2rem', lg: '8rem' },
        maxWidth: { xs: '100%', lg: '10rem' },
        // border: '1px solid',
        // borderColor: colors.grey[50],
        // bgcolor: lighten(colors.blue[50], 0.4),
        // borderRadius: '1rem',
        // boxShadow: `0 2px 3px ${lighten(colors.blue[900], 0.85)}`,
      }}
    >
      <Tooltip title='Like'>
        <ButtonStyled
          disabled={isLoading}
          variant='text'
          fullWidth
          startIcon={
            alreadySelectedType === LikeDislikeType.LIKE ? (
              <BsHandThumbsUpFill size={likeSize} />
            ) : (
              <BsHandThumbsUp size={likeSize} />
            )
          }
          sx={{ p: '0rem', borderRadius: 0, px: '1rem' }}
          onClick={onLikeClick}
        >
          {numberToWords(`${likes}`)}
        </ButtonStyled>
      </Tooltip>

      <Tooltip title='Dislike'>
        <ButtonStyled
          disabled={isLoading}
          variant='text'
          fullWidth
          startIcon={
            alreadySelectedType === LikeDislikeType.DISLIKE ? (
              <BsHandThumbsDownFill size={dislikeSize} />
            ) : (
              <BsHandThumbsDown size={dislikeSize} />
            )
          }
          sx={{
            p: '0rem',
            borderRadius: 0,
            color: 'GrayText',
            borderTop: '1x solid',
            borderColor: colors.grey[50],
          }}
          onClick={onDislikeClick}
        >
          {numberToWords(`${dislikes}`)}
        </ButtonStyled>
      </Tooltip>
    </Stack>
  );
};

import { Skeleton, Stack } from '@mui/material';

import { generateRandomArrayWithIndexes, getRandomValueFromArray } from '@utility/array';

export default function Tags() {
  return (
    <Stack direction='row' alignItems='center' justifyContent='center' flexWrap='wrap'>
      {generateRandomArrayWithIndexes(20).map((item) => (
        <Skeleton
          width={`${getRandomValueFromArray([4, 5, 7, 10])}rem`}
          height='1.6rem'
          key={item}
          sx={{ mb: 1, mr: 1, borderRadius: '10rem' }}
        />
      ))}
    </Stack>
  );
}

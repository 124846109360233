import React from 'react';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Typography, styled } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link } from 'react-router-dom';

interface BreadcrumbItem {
  label: string;
  url?: string;
}

interface SimpleBreadcrumbsProps {
  items: BreadcrumbItem[];
}

export const SimpleBreadcrumbs: React.FC<SimpleBreadcrumbsProps> = ({ items }) => {
  return (
    <Breadcrumbs separator={<NavigateNextIcon fontSize='small' />} aria-label='breadcrumb'>
      {items.map((item, index) => (
        <StyledLink key={index} color='inherit' to={item.url ?? '#'} disabled={!item.url}>
          <Typography variant='body1' sx={{ fontSize: '0.82rem', color: 'GrayText' }}>
            {item.label}
          </Typography>
        </StyledLink>
      ))}
    </Breadcrumbs>
  );
};

interface StyleLinkProps {
  disabled: boolean;
}

const StyledLink = styled(Link)<StyleLinkProps>(({ theme, disabled }) => ({
  textDecoration: disabled ? 'none !important' : 'none',
  '&:hover': { textDecoration: 'underline' },
}));

import React from 'react';

import { Skeleton, Stack } from '@mui/material';

export default function Cards() {
  return (
    <Stack direction='row' alignItems='flex-start' spacing={2}>
      <Stack alignItems='center' justifyContent='center'>
        <Skeleton
          sx={{
            borderRadius: '50%',
            width: { xs: '3rem', lg: '8rem' },
            height: { xs: '3rem', lg: '8rem' },
          }}
        />
      </Stack>
      <Stack spacing={1} width='75%'>
        <Skeleton width='100%' height='3rem' />
        <Skeleton width='100%' height='1.6rem' sx={{ mt: 1 }} />
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='flex-start'
          spacing={1}
          sx={{ pt: 1 }}
        >
          {['', '', '', '', '', ''].map((item, index) => (
            <Skeleton width='5rem' height='1.4rem' key={index} />
          ))}
        </Stack>
      </Stack>
    </Stack>
  );
}

import { setSession } from '@utility/session';

import { RegisterWithEmailPayload, UserAuthResult, UserLoginWithEmailPayload } from './type';
import API from '../_axios';

function getUrl(endpoint: string) {
  return `/auth${endpoint}`;
}

export const loginWithGoogle = async (oauthToken: string, code?: boolean) => {
  const token = oauthToken;

  const result = await API.post<{ token: string }>({
    url: getUrl('/login/google'),
    data: { token, code },
    withoutToken: true,
  });
  return result.data;
};

export const loginWithEmail = async (data: UserLoginWithEmailPayload) => {
  const result = await API.post<UserAuthResult>({
    url: getUrl('/login'),
    data,
    withoutToken: true,
  });
  return result.data;
};

export const signupWithEmail = async (data: RegisterWithEmailPayload) => {
  const result = await API.post<UserAuthResult>({
    url: getUrl('/register'),
    data,
    withoutToken: true,
  });
  return result.data;
};

export const resendVerificationEmail = async () => {
  const result = await API.post<{ message: string }>({
    url: getUrl('/resend-verification-email'),
  });
  return result.data;
};

export const verifyEmailOTP = async (token: string) => {
  const result = await API.post<{ message: string }>({
    url: getUrl('/verify/email'),
    data: { token },
  });
  return result.data;
};

export const sendPasswordForgotToken = async (email: string) => {
  const result = await API.post<{ message: string }>({
    url: getUrl('/send-otp-forgot-password'),
    data: { email },
    withoutToken: true,
  });
  return result.data;
};

export const resetPassword = async (email: string, newPassword: string, otp: string) => {
  const result = await API.post<{ message: string; token: string }>({
    url: getUrl('/reset-user-password'),
    data: { email, newPassword, otp },
    withoutToken: true,
  });

  setSession(result.data.token);
  window.location.replace('/');

  return result.data;
};

import React from 'react';

import { Stack, Typography, styled } from '@mui/material';
import { Link } from 'react-router-dom';

function AdUnit() {
  return (
    <Link
      to='#'
      onClick={(e) => {
        window.open('mailto:' + 'support@maarkar.in' + '?subject=' + `Buy AD Unit: Frulow.com`);
        e.preventDefault();
      }}
      target='blank'
      rel='noopener noreferrer'
    >
      <MainWrapper direction='column' alignItems='center' justifyContent='center'>
        <Typography variant='body2' fontWeight='bold' textAlign='center'>
          Place your ad here
        </Typography>
        <Typography variant='caption' color='GrayText' textAlign='center'>
          Get a chance to be seen by our{' '}
          <a
            href='https://twitter.com/FrulowOfficial/status/1760293265016852740'
            target='blank'
            rel='noopener noreferrer'
            onClick={(e) => e.stopPropagation()}
          >
            high traffic audience!
          </a>
        </Typography>
      </MainWrapper>
    </Link>
  );
}

export default AdUnit;

const MainWrapper = styled(Stack)(({ theme }) => ({
  cursor: 'pointer',
  height: '5em',
  width: '100%',
  background: theme.palette.bg.blue.aliceBlue,
  border: `1px dashed ${theme.palette.bg.blue.superBlue}`,
  borderRadius: '0.3em',
  marginBottom: '0.5em',
  a: {
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

import axios, { AxiosError, AxiosPromise, AxiosRequestConfig } from 'axios';
import { merge } from 'lodash';

import { AppRoutes } from '@constants/appRoutes';
import { baseURL } from '@constants/index';
import { getSessionToken } from '@utility/session';

const defaultHeaders = {
  Authorization: `Bearer ${getSessionToken()}`,
};

export interface AxiosProps extends AxiosRequestConfig {
  method?: 'get' | 'post' | 'delete' | 'patch' | 'put';
  withoutToken?: boolean;
}

const Axios = ({
  method = 'get',
  data,
  params,
  headers,
  url,
  withoutToken,
  customToken,
  transformRequest,
  transformResponse,
}: AxiosProps & { customToken?: string }): AxiosPromise => {
  let _headers = { ...headers };

  if (!withoutToken) _headers = merge(_headers, defaultHeaders); // If not include token
  if (customToken) _headers.Authorization = customToken;

  // add delay in development environment
  const delay = process.env.NODE_ENV === 'development' ? 0 : 0;

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      axios
        .request({
          baseURL,
          headers: _headers,
          data,
          params,
          url,
          method,
          transformRequest,
          transformResponse,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    }, delay);
  });
};

axios.interceptors.response.use(
  (response) => {
    // Return the response if it's successful
    return response;
  },
  (error) => {
    // Check if error.response.data.message exists
    if (error?.response?.data?.message) {
      // Flatten the error message
      error.message = error.response.data.message;
    } else if (!error?.message) {
      // If error.message doesn't exist, set a generic error message
      error.message = 'An error occurred';
    }

    // Check if the error status is 401 (Unauthorized)
    if (error?.response?.status === 401) {
      // Clear localStorage
      localStorage.clear();
      // Reload the window
      window.location.replace(AppRoutes.home);
    }

    // Pass the error along
    return Promise.reject(error);
  }
);

type CommonRequestProps = {
  url: string;
  params?: AxiosRequestConfig['params'];
  withoutToken?: boolean;
  data?: AxiosRequestConfig['data'];
  customToken?: string;
  headers?: AxiosRequestConfig['headers'];
  transformRequest?: AxiosRequestConfig['transformRequest'];
  transformResponse?: AxiosRequestConfig['transformResponse'];
};

const get = <T,>({
  params,
  withoutToken,
  url,
  data,
  customToken,
}: CommonRequestProps): AxiosPromise<T> =>
  Axios({ method: 'get', url, params, withoutToken, data, customToken });

const post = <T,>({
  params,
  withoutToken,
  url,
  data,
  headers,
  transformRequest,
  transformResponse,
}: CommonRequestProps): AxiosPromise<T> =>
  Axios({
    method: 'post',
    url,
    params,
    withoutToken,
    data,
    headers,
    transformRequest,
    transformResponse,
  });

const put = <T,>({ params, withoutToken, url, data }: CommonRequestProps): AxiosPromise<T> =>
  Axios({ method: 'put', url, params, withoutToken, data });

const Delete = <T,>({ params, withoutToken, url, data }: CommonRequestProps): AxiosPromise<T> =>
  Axios({ method: 'delete', url, params, withoutToken, data });

const API = { get, post, put, Axios, Delete, config: { defaultHeaders, baseURL } };
export default API;

import React from 'react';

import {
  Alert,
  LinearProgress,
  OutlinedInput,
  OutlinedInputProps,
  Stack,
  styled,
} from '@mui/material';

import { TextLengthInfo } from './TextAreaAutoSize';

export const TextInputOutlinedStyled = styled(OutlinedInput)(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.fontSize,
  width: '100%',
  boxSizing: 'border-box',
  borderRadius: '0.8rem',
  maxHeight: '4em',
  input: {
    padding: '0.95rem 1rem',
  },
}));

type TextLengthInfoProps = { maxLength: number; count: number };

type InputErrorAlertProps = { msg?: string };

type Props = OutlinedInputProps & {
  textInfo?: TextLengthInfoProps;
  disableTextInfo?: boolean;
  errorMsg?: string;
  isLoading?: boolean;
};

export function TextInput({ textInfo, disableTextInfo, errorMsg, isLoading, ...rest }: Props) {
  return (
    <>
      <TextInputOutlinedStyled error={!!errorMsg} {...rest} />
      {isLoading && (
        <LinearProgress
          sx={{ width: '97%', margin: '0 auto', mt: -0.6, borderRadius: '2rem 2rem 0 0' }}
        />
      )}
      <Stack
        direction='row'
        alignItems='center'
        justifyContent={errorMsg ? 'space-between' : 'flex-end'}
      >
        {errorMsg && <InputErrorAlert msg={errorMsg} />}
        {!disableTextInfo && textInfo && <TextLengthInfo {...textInfo} />}
      </Stack>
    </>
  );
}

export function InputErrorAlert({ msg }: InputErrorAlertProps) {
  return (
    msg && (
      <Alert
        sx={{
          p: 0,
          fontSize: '0.9rem',
          bgcolor: 'transparent',
          color: 'red',
          whiteSpace: 'pre-line',
          px: 1,
        }}
        severity='error'
      >
        {msg}
      </Alert>
    )
  );
}

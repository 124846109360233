import { SyntheticEvent, useCallback } from 'react';

import { useAppDispatch, useAppSelector } from '@app/hooks';
import { ModalTypes, getRootUser, rootActions } from '@app/root/slice';

const usePermission = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(getRootUser);

  // Function to check if the user is valid
  const isUserValid = useCallback(() => user && user.id, [user]);

  const isOwner = useCallback((id: number) => user?.id === id, []);

  const handleUserLogin = useCallback(() => {
    if (!isUserValid()) {
      console.warn('User is not valid. Cannot perform secure action.');
      dispatch(rootActions.setActiveModal(ModalTypes.AUTH));
    }
  }, [dispatch, isUserValid]);

  const secureCallback = useCallback(
    <T>(arg: T) => {
      if (isUserValid()) return arg;
      else return handleUserLogin;
    },
    [handleUserLogin, isUserValid]
  );

  // Wrapper function for secure actions
  const secureActionWrapper = useCallback(
    <T>(secureAction: T) => {
      if (isUserValid()) {
        if (typeof secureAction === 'function') return (e: any) => secureAction(e);

        return (e?: SyntheticEvent<HTMLButtonElement>) => {
          if (e) {
            e.stopPropagation();
            e.preventDefault();
          }
          return secureAction;
        };
      } else {
        return (e?: SyntheticEvent<HTMLButtonElement>) => {
          if (e) {
            e.stopPropagation();
            e.preventDefault();
          }
          handleUserLogin();
        };
      }
    },
    [handleUserLogin, isUserValid]
  );

  return { secureActionWrapper, isUserValid, handleUserLogin, isOwner, secureCallback };
};

export default usePermission;

import ReactGA from 'react-ga4';

ReactGA.initialize('G-J5SYGXD8K3', {
  testMode: process.env.NODE_ENV === 'development',
});

ReactGA.send({
  hitType: 'pageview',
  page: window.location.pathname + window.location.search,
});

export default ReactGA;

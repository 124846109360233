import { SyntheticEvent, useCallback, useEffect, useState } from 'react';

import { LikeDislikeType } from '@constants/index';
import { likeDislikeCreateUpdate } from 'api/postReaction';

type LikeDislikeManagerHook = {
  likes: number;
  dislikes: number;
  isLoading: boolean;
  updateLikesDislikes: (postId: number, type: LikeDislikeType) => () => void;
  alreadySelectedType: LikeDislikeType;
};

export type LikeDislikeManagerProps = {
  initialLikes: number;
  initialDislikes: number;
  alreadySelectedType: LikeDislikeType;
  postId: number;
  onChange?: (likesCount: number, dislikes: number, type: LikeDislikeType) => void;
};

const useLikeDislikeManager = (
  initialLikes: number,
  initialDislikes: number,
  alreadySelectedType: LikeDislikeType,
  postId: number,
  onChange?: (likesCount: number, dislikes: number, type: LikeDislikeType) => void
): LikeDislikeManagerHook => {
  const [likes, setLikes] = useState(initialLikes ?? 0);
  const [dislikes, setDislikes] = useState(initialDislikes ?? 0);
  const [isLoading, setIsLoading] = useState(false);
  const [prevType, setPrevType] = useState(alreadySelectedType ?? LikeDislikeType.NONE);

  const handleLikeDislike = useCallback(
    (type: LikeDislikeType) => {
      // Initialize updatedLikes and updatedDislikes with current values
      let updatedLikes = +likes;
      let updatedDislikes = +dislikes;
      const updatedType = type === prevType ? LikeDislikeType.NONE : type;

      // If user has cancelled vote by clicking the same type again
      if (prevType === type) {
        if (prevType === LikeDislikeType.LIKE) updatedLikes -= 1;
        else if (prevType === LikeDislikeType.DISLIKE) updatedDislikes -= 1;
      } else {
        if (prevType === LikeDislikeType.LIKE) {
          // User already selected LIKE
          if (type === LikeDislikeType.DISLIKE) {
            // Change from LIKE to DISLIKE
            updatedLikes -= 1;
            updatedDislikes += 1;
          }
        } else if (prevType === LikeDislikeType.DISLIKE) {
          // User already selected DISLIKE
          if (type === LikeDislikeType.LIKE) {
            // Change from DISLIKE to LIKE
            updatedLikes += 1;
            updatedDislikes -= 1;
          }
        } else {
          // User has not selected any type yet or already selected NONE
          if (type === LikeDislikeType.LIKE) {
            // User selects LIKE
            updatedLikes += 1;
          } else if (type === LikeDislikeType.DISLIKE) {
            // User selects DISLIKE
            updatedDislikes += 1;
          }
        }
      }

      setLikes(updatedLikes);
      setDislikes(updatedDislikes);
      setPrevType(updatedType);

      // Call another function inline with the updated counts
      onChange?.(updatedLikes, updatedDislikes, updatedType);
    },
    [dislikes, likes, onChange, prevType]
  );

  const updateLikesDislikes =
    (postId: number, type: LikeDislikeType) => async (e?: SyntheticEvent<HTMLButtonElement>) => {
      if (e) {
        e.stopPropagation();
        e.preventDefault();
      }
      try {
        setIsLoading(true);

        // Make an API call to update the likes/dislikes on the server
        // Replace the API_URL with your actual API endpoint
        await likeDislikeCreateUpdate(postId, type === prevType ? LikeDislikeType.NONE : type);

        handleLikeDislike(type);
      } catch (error) {
        console.error('Error updating likes/dislikes', error);
      } finally {
        setIsLoading(false);
      }
    };

  useEffect(() => {
    setDislikes(initialDislikes);
    setLikes(initialLikes);
    setPrevType(alreadySelectedType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialDislikes, initialLikes, alreadySelectedType]);

  return {
    likes,
    dislikes,
    isLoading,
    updateLikesDislikes,
    alreadySelectedType: prevType,
  };
};

export default useLikeDislikeManager;

import React from 'react';

import { Button, CircularProgress, Divider, Stack, colors } from '@mui/material';
import { BsChevronDoubleDown } from 'react-icons/bs';

type Props = {
  isError: boolean;
  isFetchingNextPage: boolean;
  loadMore: () => void;
  hasNextPage: boolean;
  height?: string;
};

const EndComponent = React.forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const { isError, isFetchingNextPage, loadMore, hasNextPage, height } = props;

  const btnText = isFetchingNextPage ? 'Loading more...' : 'Load more';

  const nextPageIcon = hasNextPage ? <BsChevronDoubleDown /> : undefined;
  return (
    <Stack alignItems='center' justifyContent='center' height={height ?? '100%'}>
      {!isError && (
        <Divider sx={{ width: '100%' }}>
          <Button
            variant='contained'
            endIcon={isFetchingNextPage ? <CircularProgress size='1.2rem' /> : nextPageIcon}
            sx={{
              bgcolor: colors.blueGrey[600],
              p: 0.5,
              px: 2,
              fontSize: '0.9rem',
              textTransform: 'none',
            }}
            onClick={loadMore}
            disabled={isFetchingNextPage || isError || !hasNextPage}
            ref={ref}
          >
            {hasNextPage ? btnText : 'No more items'}
          </Button>
        </Divider>
      )}
    </Stack>
  );
});

export default EndComponent;

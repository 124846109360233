import { useCallback, useEffect, useState } from 'react';

import { useQuery } from '@tanstack/react-query';

import { useRouter } from '@hooks/useRouter';
import ReactGA from '@libs/GoogleAnalytics';
import { queryKeys } from 'api';
import { getIsPostTrending, getPostById } from 'api/post';
import { GetPostByIdResponse } from 'api/post/types';
import { getPostReactions } from 'api/postReaction';

function useDetailsDrawer() {
  const { parseSearchParams, modifySearchParams } = useRouter();
  const params = parseSearchParams() as { drawer_id: string };

  const [open, setOpen] = useState(false);

  const data = useQuery(
    [queryKeys.getPostById, params.drawer_id],
    () => getPostById(+params.drawer_id),
    {
      enabled: !!params.drawer_id,
    }
  );

  const reactions = useQuery(
    [queryKeys.getReactionsByPostId, params.drawer_id],
    () => getPostReactions(+params.drawer_id),
    {
      enabled: !!params.drawer_id,
    }
  );

  const isTrendingData = useQuery(
    [queryKeys.getIsPostTrending, params.drawer_id],
    () => getIsPostTrending(+params.drawer_id),
    { enabled: !!params.drawer_id }
  );

  const handleClose = useCallback(() => {
    modifySearchParams((prev) => {
      prev.delete('drawer_id');
      prev.delete('active_tab');
      return prev;
    });
  }, [modifySearchParams]);

  useEffect(() => {
    if (params?.drawer_id && data.data) {
      setOpen(true);
      // Track creation failure
      ReactGA.event({
        category: 'DetailsDrawer',
        action: 'post_view',
        label: `Post view ${params.drawer_id}`, // Use post title if available, otherwise fallback
        value: 1,
      });
    } else setOpen(false);
  }, [data.data, params?.drawer_id]);

  return {
    open,
    activeId: params.drawer_id,
    data: {
      ...data.data,
      isTrending: isTrendingData?.data,
      ...reactions.data,
    } as GetPostByIdResponse & {
      isTrending: number | false;
    },
    onClose: handleClose,
  };
}

export default useDetailsDrawer;

import { useCallback } from 'react';

import {
  Box,
  Divider,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Popover,
  PopoverProps,
  Stack,
  Tooltip,
  colors,
  styled,
} from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import moment from 'moment';
import { BsPencilSquare, BsPlusLg, BsTrash } from 'react-icons/bs';
import { Link } from 'react-router-dom';

import { AppRoutes } from '@constants/appRoutes';
import { useConfirmBox } from '@hoc/confirm.context';
import { isValidArray } from '@utility/array';
import { preventDefaultWrapper } from '@utility/button';
import { queryKeys } from 'api';
import { deleteOwnPostById, getUsersOwnPost } from 'api/post';

type Props = Omit<PopoverProps, 'open'>;

export function DraftSelectPopover({ anchorEl, ...rest }: Readonly<Props>) {
  const { askConfirm } = useConfirmBox();
  const { data, isFetching, refetch } = useQuery(
    [queryKeys.getOwnPosts, { draftOnly: true }],
    () => getUsersOwnPost({ draftOnly: true }),
    { enabled: Boolean(anchorEl) }
  );

  const deleteMutate = useMutation(deleteOwnPostById, {
    onSuccess: () => {
      refetch();
    },
  });

  const handlePostDelete = useCallback(
    async (id: number) => {
      const confirmed = await askConfirm(
        'Delete?',
        `Are you sure you want to delete saved draft - ${
          data?.find((item) => item.id === id)?.name
        }?`
      );
      if (!confirmed) return;

      deleteMutate.mutate(id);
    },
    [askConfirm, data, deleteMutate]
  );

  const hasDraftItems = !!isValidArray(data);

  return (
    <Popover
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      {...rest}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      slotProps={{
        paper: {
          sx: {
            marginLeft: -10,
            maxHeight: '70vh',
            bgcolor: 'white',
            borderRadius: '0.8rem',
            boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
          },
        },
      }}
    >
      {!isFetching ? (
        <Box
          sx={{
            bgcolor: 'common.white',
            minWidth: hasDraftItems ? '25rem' : undefined,
          }}
        >
          <List>
            {hasDraftItems && (
              <ListSubheader
                sx={{
                  bgcolor: colors.blue[800],
                  color: 'common.white',
                  borderRadius: '0.3rem 0.3rem 0 0',
                }}
              >
                Continue saved drafts
              </ListSubheader>
            )}
            <Link
              to={AppRoutes.newPost}
              onClick={() => rest?.onClose?.({} as any, 'backdropClick')}
            >
              <ListItemStyled
                sx={{
                  mt: hasDraftItems ? 1 : 0,
                }}
              >
                <ListItemButton>
                  <ListItemIcon>
                    <BsPlusLg />
                  </ListItemIcon>
                  <ListItemText primary='Create New' />
                </ListItemButton>
              </ListItemStyled>
            </Link>
            {hasDraftItems && (
              <>
                <Divider sx={{ fontSize: '0.85rem', color: 'GrayText' }}>OR</Divider>

                {data?.map((item) => (
                  <Link
                    key={item.id}
                    to={{ pathname: AppRoutes.newPost, search: 'draft_id=' + item.id }}
                    onClick={() => rest?.onClose?.({} as any, 'backdropClick')}
                  >
                    <ListItemStyled>
                      <ListItemButton
                        disabled={deleteMutate.isLoading || isFetching}
                        sx={{ position: 'relative' }}
                      >
                        <ListItemIcon>
                          <BsPencilSquare />
                        </ListItemIcon>
                        <ListItemText
                          primary={item.name}
                          secondary={'Last updated: ' + moment(item.updatedAt.toString()).fromNow()}
                          secondaryTypographyProps={{ fontSize: '0.7rem', color: 'GrayText' }}
                        />
                        <Tooltip title='Delete'>
                          <IconButton
                            size='small'
                            sx={{ width: '3.5rem', height: '3.5rem', p: 0.5 }}
                            onClick={preventDefaultWrapper(() => handlePostDelete(item.id), true)}
                          >
                            <BsTrash />
                          </IconButton>
                        </Tooltip>
                      </ListItemButton>
                    </ListItemStyled>
                  </Link>
                ))}
              </>
            )}
          </List>
        </Box>
      ) : (
        <Stack
          alignItems='center'
          justifyContent='center'
          sx={{ bgcolor: 'common.white', minWidth: '10rem', py: 1 }}
        >
          <LinearProgress sx={{ width: '100%' }} />
        </Stack>
      )}
    </Popover>
  );
}

const ListItemStyled = styled(ListItem)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  padding: 0,
  borderRadius: '1rem',

  '& .MuiListItemIcon-root': {
    minWidth: '3rem',
    fontSize: '1.4rem',
  },

  '& .MuiButtonBase-root': {
    padding: '0.5rem 1.2rem',
  },

  '& .MuiListItemText-primary': {
    fontSize: '0.9rem',
    fontWeight: '700',
  },
}));

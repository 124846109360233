import React, { useEffect } from 'react';

import { Box } from '@mui/material';

interface GoogleAdsWrapperProps {
  horizontal?: boolean;
  adClientId: string;
  adSlot: string;
}

// Declare adsbygoogle globally
declare global {
  interface Window {
    adsbygoogle?: { push: (x: any) => void };
  }
}

export const AdsWrapper: React.FC<GoogleAdsWrapperProps> = ({
  horizontal = true,
  adClientId,
  adSlot,
}) => {
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (window.adsbygoogle && !window.adsbygoogle.loaded) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    }
  }, []);

  if (process.env.NODE_ENV === 'development') return <></>;

  return (
    <Box
      sx={{
        width: '100%', // Ensure the parent container has a width
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <ins
        className='adsbygoogle'
        style={{ display: 'block', minWidth: '250px' }} // Specify a minimum width for the ad
        data-ad-format='fluid'
        data-ad-layout-key='-gb-22-1g-bp+172'
        data-ad-client={adClientId}
        data-ad-slot={adSlot}
      ></ins>
    </Box>
  );
};

import { Button, Stack, Typography, styled } from '@mui/material';

import Error1JPG from '@assets/images/auth/1.jpeg';
import { useRouter } from '@hooks/useRouter';

export function ErrorBoundary() {
  const { replace } = useRouter();

  const _handlePageReload = () => {
    window.location.reload();
  };

  const _handleHomeClick = () => {
    replace('/');
  };

  return (
    <Stack
      alignItems='center'
      justifyContent='center'
      width='100vw'
      height='100vh'
      sx={{ bgcolor: 'white', p: 3 }}
    >
      <Stack width='100%' height='100%' maxWidth='25em' pt={10} alignItems='center'>
        <ErrorImage src={Error1JPG} alt='Frulow Error Screen' />
        <Typography variant='h5' mt={2}>
          Unexpected Error
        </Typography>
        <Typography variant='body1'>Something went wrong, please try again.</Typography>
        <Stack direction='row' alignItems='center' spacing={2} sx={{ mt: 5 }}>
          <Button variant='outlined' fullWidth disableElevation onClick={_handleHomeClick}>
            Home
          </Button>
          <Button fullWidth disableElevation onClick={_handlePageReload}>
            Refresh
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
}

const ErrorImage = styled('img')`
  width: 100%;
  height: max-content;
  height: 16.7em;
`;

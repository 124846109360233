import React, { useEffect, useState } from 'react';

import { Avatar, Box, Link, Paper, Stack, Typography } from '@mui/material';
import ColorThief from 'colorthief';

import { AppRoutes } from '@constants/appRoutes';
import { FeaturedPostsResponse } from 'api/post/types';

export default function FeaturedPosts(item: FeaturedPostsResponse[number]) {
  const [adjustedColor, setAdjustedColor] = useState('rgba(0, 0, 0, 1)');
  const [textColor, setTextColor] = useState('#fff'); // Default text color to white

  useEffect(() => {
    const imageUrl = item.galleryImages?.[0]?.location;
    if (imageUrl) {
      const img = new Image();
      img.crossOrigin = 'Anonymous'; // Ensure cross-origin images work correctly
      img.src = imageUrl;

      img.onload = () => {
        const colorThief = new ColorThief();
        const [r, g, b] = colorThief.getColor(img); // Get the dominant color from the image
        const dominantColor = `rgb(${r}, ${g}, ${b})`;
        setAdjustedColor(dominantColor); // Set the dominant color as background

        // Calculate the luminance of the color to determine text color
        const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
        setTextColor(luminance > 0.6 ? '#000' : '#fff'); // Set text to black if light, else white
      };
    }
  }, [item.galleryImages]);

  return (
    <Link
      href={AppRoutes.postDetail({ id: `${item.id}` })}
      underline='none'
      sx={{ width: '100%', height: 'max-content' }}
    >
      <Paper
        key={item.id}
        sx={{
          overflow: 'hidden',
          height: '20em',
          width: '100%',
          backgroundImage: `url(${item.galleryImages?.[0]?.location})`,
          backgroundSize: '100% 100%', // Ensures the image fits within the component without being cropped
          backgroundRepeat: 'no-repeat', // Prevents the image from repeating
          backgroundPosition: 'center', // Centers the image
          backgroundColor: 'transparent', // Makes the background transparent if needed
        }}
      >
        <Stack
          height='100%'
          justifyContent='flex-end'
          sx={{
            backgroundImage: `linear-gradient(to top, ${adjustedColor} 10%, rgba(255, 255, 255, 0))`, // Fading effect to transparent at the top
          }}
        >
          <Stack direction='column' alignItems='center' justifyContent={'center'}>
            <Avatar src={item.thumbnailUrl as string} sx={{ width: '4em', height: '4em' }}>
              {item.name[0]}
            </Avatar>
            <Box
              sx={{
                px: 4,
                py: 4,
                pt: 1,
                borderRadius: 1,
                color: textColor, // Dynamic text color based on luminance
              }}
            >
              <Typography
                textAlign='center'
                variant='h1'
                sx={{ fontSize: '1.8rem', fontWeight: 600 }}
              >
                {item.name}
              </Typography>

              <Typography textAlign='center' variant='body1' sx={{ fontSize: '1rem' }}>
                {item.tagline}
              </Typography>
              <Typography
                textAlign='center'
                variant='body2'
                sx={{ fontSize: '0.8rem', opacity: 0.7, mt: 1 }}
              >
                by {item.author.fullName}
              </Typography>
            </Box>
          </Stack>
        </Stack>
      </Paper>
    </Link>
  );
}

import { useCallback, useMemo, useState } from 'react';

import {
  Alert,
  Avatar,
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Modal,
  Stack,
  Typography,
  colors,
  styled,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useForm } from 'react-hook-form';
import { BsEye, BsEyeSlash, BsRepeat, BsXLg } from 'react-icons/bs';

import { useAppDispatch, useAppSelector } from '@app/hooks';
import { getModalConfig, rootActions } from '@app/root/slice';
import { useRouter } from '@hooks/useRouter';
import ReactGA from '@libs/GoogleAnalytics';
import { setSession } from '@utility/session';
import { loginWithEmail, signupWithEmail } from 'api';

import FrogotPassword from './FrogotPassword';
import GoogleLoginProvider from './GoogleLogin';
import { LoginFields, SignupFields } from './type';
import { StackRow, TextInput } from '..';

function AuthModal() {
  const dispatch = useAppDispatch();
  const { replace } = useRouter();

  const modalConfig = useAppSelector(getModalConfig);
  const [showPass, setShowPass] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SignupFields>();

  const loginResult = useMutation(loginWithEmail);
  const signupResult = useMutation(signupWithEmail);

  const handleFormToggle = () => setIsLogin((prev) => !prev);

  const handleToggleIsForgotPassword = useCallback(() => setIsForgotPassword((prev) => !prev), []);

  const handleModalClose = useCallback(() => {
    dispatch(rootActions.setActiveModal(null));
    if (modalConfig?.redirectOnCloseTo) replace(modalConfig.redirectOnCloseTo);
  }, [dispatch, modalConfig?.redirectOnCloseTo, replace]);

  const handleFormSubmit = async (data: LoginFields | SignupFields) => {
    try {
      let token: string | null = null;

      if (isLogin) {
        const res = await loginResult.mutateAsync(data);
        token = res.token;
      } else {
        const res = await signupResult.mutateAsync(data as SignupFields);
        token = res.token;
      }

      setSession(token);
      // Track successful signup
      ReactGA.event({
        category: 'User',
        action: isLogin ? 'login' : 'signup',
        label: isLogin ? 'login_form' : 'signup_form', // Optional label for specific signup form
        value: 1,
      });
      window.location.reload();
    } catch (error) {
      console.error(error);
      // Track unsuccessful signup
      ReactGA.event({
        category: 'User',
        action: isLogin ? 'login' : 'signup',
        label: isLogin ? 'login_form' : 'signup_form', // Optional label for specific signup form
        value: -1,
      });
    }
  };

  const error = (signupResult.error || loginResult.error) as AxiosError;

  const submitBtnText = useMemo(() => {
    let submitText = isLogin ? 'Login' : 'Sign Up';
    if (loginResult.isLoading || signupResult.isLoading) {
      submitText = isLogin ? 'Logging in...' : 'Signing up...';
    }
    return submitText;
  }, [isLogin, loginResult.isLoading, signupResult.isLoading]);

  return (
    <Modal open slotProps={{ backdrop: { sx: { bgcolor: '#e3f2fdf2' } } }}>
      {isForgotPassword ? (
        <FrogotPassword onClose={handleToggleIsForgotPassword} />
      ) : (
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='center'
          sx={{ height: '100dvh' }}
        >
          <Stack
            sx={{
              p: 4,
              width: '30rem',
              minHeight: '30rem',
              bgcolor: (theme) => theme.palette.common.white,
              borderRadius: '1rem',
              boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
              position: 'relative',
            }}
            component='form'
            onSubmit={handleSubmit(handleFormSubmit)}
          >
            {!modalConfig?.disableClose && (
              <CloseButtonStyled
                disabled={loginResult.isLoading || signupResult.isLoading}
                onClick={handleModalClose}
              >
                <BsXLg />
              </CloseButtonStyled>
            )}

            <Stack
              direction='row'
              alignItems='flex-start'
              justifyContent='flex-start'
              sx={{ mb: 3 }}
            >
              <Avatar src='/logo_512x512.png' sx={{ mt: 1, width: '3.6rem', height: '3.6rem' }} />
              <Box sx={{ pl: 2 }}>
                <Typography variant='h6' sx={{ fontSize: '1.6rem', fontWeight: 700 }}>
                  {isLogin ? 'Login' : 'Signup'}
                </Typography>
                <Typography variant='body2' sx={{ color: 'GrayText' }}>
                  Discover and share hidden gems in the world of libraries
                </Typography>
              </Box>
            </Stack>
            {(loginResult.isError || signupResult.isError) && (
              <Alert sx={{ mb: 2, borderRadius: 4 }} severity='error'>
                {error.message}
              </Alert>
            )}

            <StackRow alignItems='center' justifyContent='center' sx={{ py: 2 }}>
              <GoogleLoginProvider />
            </StackRow>

            <Divider>
              <Typography variant='caption' color='GrayText'>
                OR
              </Typography>
            </Divider>

            {!isLogin && (
              <Stack
                direction='row'
                justifyContent='center'
                alignItems='flex-start'
                spacing={3}
                sx={{ mt: 2 }}
              >
                <Box width='50%'>
                  <Typography sx={{ fontSize: '1rem', mb: 1, color: 'GrayText', fontWeight: 500 }}>
                    First name
                  </Typography>
                  <TextInput
                    placeholder='First name'
                    inputProps={{
                      ...register('firstName', {
                        required: { value: true, message: 'First name is required' },
                        minLength: { value: 3, message: 'Must be at least 3 characters long' },
                        maxLength: { value: 16, message: 'Must not exceed 16 characters' },
                      }),
                    }}
                    errorMsg={errors.firstName?.message}
                  />
                </Box>
                <Box width='50%'>
                  <Typography sx={{ fontSize: '1rem', mb: 1, color: 'GrayText', fontWeight: 500 }}>
                    Last name
                  </Typography>
                  <TextInput
                    placeholder='Last name'
                    inputProps={{
                      ...register('lastName', {
                        required: { value: true, message: 'Last name is required' },
                        minLength: {
                          value: 3,
                          message: 'Last name must be at least 3 characters long',
                        },
                        maxLength: {
                          value: 16,
                          message: 'Last name must not exceed 16 characters',
                        },
                      }),
                    }}
                    errorMsg={errors.lastName?.message}
                  />
                </Box>
              </Stack>
            )}

            <Box sx={{ mt: !isLogin ? 2.6 : 0 }}>
              <Typography sx={{ fontSize: '1rem', mb: 1, color: 'GrayText', fontWeight: 500 }}>
                Email
              </Typography>

              <TextInput
                placeholder='Email'
                type='email'
                inputProps={{
                  ...register('email', {
                    required: { value: true, message: 'Email is required' },
                    validate: (val) => {
                      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                      return emailRegex.test(val) ? true : 'Email must be valid';
                    },
                  }),
                }}
                errorMsg={errors.email?.message}
              />
            </Box>
            <Box sx={{ mt: 2.6, mb: isLogin ? 0 : 3 }}>
              <Typography sx={{ fontSize: '1rem', mb: 1, color: 'GrayText', fontWeight: 500 }}>
                Password
              </Typography>
              <TextInput
                placeholder='Password'
                type={showPass ? 'text' : 'password'}
                endAdornment={
                  <IconButton size='small' onClick={() => setShowPass((prev) => !prev)}>
                    {showPass ? <BsEyeSlash /> : <BsEye />}
                  </IconButton>
                }
                inputProps={{
                  ...register('password', {
                    required: { value: true, message: 'Password is required' },
                    minLength: { value: 8, message: 'Password must be at least 8 characters long' },
                    maxLength: { value: 20, message: 'Password must not exceed 20 characters' },
                    validate: (val: string) => {
                      if (!isLogin) {
                        const strongPassRegex =
                          /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^*-]).{8,}$/;

                        if (!strongPassRegex.test(val)) {
                          return 'Password must contain at least one lowercase letter, one uppercase letter, one digit, and one special character (#?!@$%^*-). Minimum length is 8 characters.';
                        }
                      }
                      return true;
                    },
                  }),
                }}
                errorMsg={errors.password?.message}
              />
            </Box>
            {isLogin && (
              <StackRow justifyContent='flex-end' sx={{ mt: 1 }}>
                <Typography variant='body2' sx={{ color: 'GrayText', fontSize: '0.9rem', pl: 2 }}>
                  Don&apos;t remember your password?
                </Typography>
                <Button
                  onClick={handleToggleIsForgotPassword}
                  variant='text'
                  sx={{ p: 0.5, px: 2, color: 'InfoText', fontSize: '0.9rem', fontWeight: 400 }}
                >
                  Recover now
                </Button>
              </StackRow>
            )}

            <StackRow justifyContent='space-between' sx={{ mt: 2 }} spacing={3}>
              <Button
                fullWidth
                variant='text'
                startIcon={<BsRepeat />}
                onClick={handleFormToggle}
                disabled={loginResult.isLoading || signupResult.isLoading}
              >
                {isLogin ? 'Sign up' : 'Login'}
              </Button>
              <Button
                fullWidth
                type='submit'
                disabled={loginResult.isLoading || signupResult.isLoading}
              >
                {loginResult.isLoading ? (
                  <CircularProgress
                    sx={{ color: 'common.white', mr: 1, maxWidth: '1.6rem', maxHeight: '1.6rem' }}
                  />
                ) : (
                  submitBtnText
                )}
              </Button>
            </StackRow>
          </Stack>
        </Stack>
      )}
    </Modal>
  );
}

export default AuthModal;

const CloseButtonStyled = styled(IconButton)`
  background-color: ${colors.red[300]};
  color: ${({ theme }) => theme.palette.common.white};
  width: 2.6rem;
  height: 2.6rem;
  position: absolute;
  right: -0.5rem;
  top: -0.5rem;

  &:hover {
    background-color: ${colors.red[800]};
  }
`;

/* eslint-disable react/prop-types */
import React, { useRef } from 'react';

import { Box, Button, ButtonProps, LinearProgress, SxProps, Tooltip } from '@mui/material';

type Props = ButtonProps & { isLoading?: boolean; boxProps?: SxProps };

export const ButtonWithLoader = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { isLoading, boxProps, ...buttonProps } = props;
  const itemRef = useRef<HTMLButtonElement>(null);

  return (
    <Box sx={{ ...boxProps }} ref={ref}>
      <Tooltip title={buttonProps.title}>
        <Button ref={itemRef} {...buttonProps} sx={{ ...buttonProps.sx, position: 'relative' }}>
          {buttonProps.children}
          {isLoading && (
            <Box
              sx={{
                width: itemRef.current ? itemRef.current?.clientWidth + 5 : '100%',
                px: 0.5,
                position: 'absolute',
                zIndex: 111,
                bottom: -0.4,
              }}
            >
              <LinearProgress sx={{ borderRadius: '0 0 1rem 1rem', height: '2px' }} />
            </Box>
          )}
        </Button>
      </Tooltip>
    </Box>
  );
});
